import { useEffect, useState } from "react";
import { getBuybackReviews, updateBuybackReview } from "../api/AdminApi";
import { BuybackReviewTable } from "../types/types";

interface ReviewBuybackItem {
  productName: string;
  id: number;
  reviewId: number;
  csDecision: "PENDING" | "REJECTED" | "ACCEPTED" | "CONDITIONALLY_ACCEPTED";
  buybackPrice: string;
  customerAddress: string;
  // images: string[];
}

export function ReviewBuyback() {
  const [data, setData] = useState<ReviewBuybackItem[]>([]);
  const [isRejectAlertVisible, setIsRejectAlertVisible] = useState(false);
  const [isConditionalAcceptAlertVisible, setIsConditionalAcceptAlertVisible] =
    useState(false);
  const [comments, setComments] = useState<{ [key: number]: string }>({});
  const [popupComment, setPopupComment] = useState("");
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  );
  const [selectedReviewId, setSelectedReviewId] = useState<number | null>(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await getBuybackReviews();
        const reviews: ReviewBuybackItem[] = response.data.map(
          (item: BuybackReviewTable) => ({
            productName: item.product_name,
            buybackPrice: item.buyback_price,
            customerAddress: item.customer_address,
            // images: item.images,
            csDecision: item.cs_review_status || "PENDING",
            id: item.item_id,
            reviewId: item.id,
          })
        );
        setData(reviews);
      } catch (error) {
        console.error("Error fetching buyback reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  const handleUpdateReview = async (
    productId: number,
    reviewId: number,
    status: "ACCEPTED" | "CONDITIONALLY_ACCEPTED",
    productName: string
  ) => {
    try {
      await updateBuybackReview(productId, reviewId, {
        csReviewStatus: status,
      });

      // Update local state immediately
      setData((prevData) =>
        prevData.map((item) =>
          item.id === productId ? { ...item, csDecision: status } : item
        )
      );

      alert(`Product ${productName} has been marked as ${status}`);
    } catch {
      alert("Failed to update review.");
    }
  };

  const handleReject = async () => {
    if (selectedProductId && selectedReviewId) {
      try {
        const productName =
          data.find((item) => item.id === selectedProductId)?.productName ||
          "Unknown Product";
        await updateBuybackReview(selectedProductId, selectedReviewId, {
          csReviewStatus: "REJECTED",
        });

        // Update local state immediately
        setData((prevData) =>
          prevData.map((item) =>
            item.id === selectedProductId
              ? { ...item, csDecision: "REJECTED" }
              : item
          )
        );

        alert(`Product ${productName} has been rejected.`);
        resetState(); // Close the popup and reset state after rejection
      } catch {
        alert("Failed to reject review.");
      }
    }
  };

  const handleConditionalAccept = async () => {
    if (selectedProductId && selectedReviewId) {
      try {
        const productName =
          data.find((item) => item.id === selectedProductId)?.productName ||
          "Unknown Product";
        await updateBuybackReview(selectedProductId, selectedReviewId, {
          csReviewStatus: "CONDITIONALLY_ACCEPTED",
        });

        // Update local state immediately
        setData((prevData) =>
          prevData.map((item) =>
            item.id === selectedProductId
              ? { ...item, csDecision: "CONDITIONALLY_ACCEPTED" }
              : item
          )
        );

        alert(`Product ${productName} has been conditionally accepted.`);
        resetState(); // Close the popup and reset state after conditional acceptance
      } catch {
        alert("Failed to update review.");
      }
    }
  };

  const resetState = () => {
    setIsRejectAlertVisible(false);
    setIsConditionalAcceptAlertVisible(false);
    setPopupComment("");
    setSelectedProductId(null);
    setSelectedReviewId(null);
  };

  const rejectProduct = (id: number, reviewId: number) => {
    setSelectedProductId(id);
    setSelectedReviewId(reviewId);
    setIsRejectAlertVisible(true);
  };

  const conditionalAcceptProduct = (id: number, reviewId: number) => {
    setSelectedProductId(id);
    setSelectedReviewId(reviewId);
    setIsConditionalAcceptAlertVisible(true);
  };

  const handleCommentChange = (productId: number, value: string) => {
    setComments((prev) => ({ ...prev, [productId]: value }));
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Review Buyback
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {data.map((item) => (
          <div
            key={item.id}
            className="bg-white rounded-lg shadow-md p-6 relative"
          >
            {/* Badge with dynamic color based on csDecision */}
            <div
              className={`absolute top-2 right-2 text-xs px-2 py-1 rounded ${
                item.csDecision === "ACCEPTED"
                  ? "bg-green-500 text-white"
                  : item.csDecision === "CONDITIONALLY_ACCEPTED"
                  ? "bg-blue-500 text-white"
                  : item.csDecision === "REJECTED"
                  ? "bg-red-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {item.csDecision}
            </div>
            {/* Product Images */}
            <div className="flex gap-2 mb-4">
              {/* {item.images.map((image, imgIndex) => (
                <img
                  key={imgIndex}
                  src={image}
                  alt={`${item.productName} image ${imgIndex + 1}`}
                  className="w-20 h-20 object-cover rounded-md"
                />
              ))} */}
            </div>
            {/* Product Information */}
            <h3 className="text-lg font-semibold mb-2 text-left">
              PRODUCT NAME
            </h3>
            <p className="mb-4 text-left">{item.productName}</p>
            <h3 className="text-lg font-semibold mb-2 text-left">
              BUYBACK PRICE (APPROX)
            </h3>
            <p className="mb-4 text-left">${item.buybackPrice}</p>
            <h3 className="text-lg font-semibold mb-2 text-left">
              CUSTOMER ADDRESS
            </h3>
            <p className="mb-4 text-left">{item.customerAddress}</p>
            {/* Comments Section */}
            <textarea
              placeholder="Add comments here..."
              className="w-full p-2 border rounded-lg bg-white text-left"
              value={comments[item.id] || ""}
              onChange={(e) => handleCommentChange(item.id, e.target.value)}
            />
            {/* Action Buttons */}
            <div className="flex gap-2 mb-4 mt-2">
              <button
                className={`flex-1 px-4 py-2 rounded-full text-center ${
                  item.csDecision === "ACCEPTED"
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-green-500 text-white"
                }`}
                onClick={() =>
                  handleUpdateReview(
                    item.id,
                    item.reviewId,
                    "ACCEPTED",
                    item.productName
                  )
                }
                disabled={item.csDecision === "ACCEPTED"}
              >
                ACCEPT
              </button>
              <button
                className={`flex-1 px-4 py-2 rounded-full text-center ${
                  item.csDecision === "CONDITIONALLY_ACCEPTED"
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-blue-500 text-white"
                }`}
                onClick={() => conditionalAcceptProduct(item.id, item.reviewId)}
                disabled={item.csDecision === "CONDITIONALLY_ACCEPTED"}
              >
                CONDITIONAL ACCEPT
              </button>
              <button
                className={`flex-1 px-4 py-2 rounded-full text-center ${
                  item.csDecision === "REJECTED"
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-red-500 text-white"
                }`}
                onClick={() => rejectProduct(item.id, item.reviewId)}
                disabled={item.csDecision === "REJECTED"}
              >
                REJECT
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Reject Alert Dialog */}
      {isRejectAlertVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={resetState}
          />
          <div className="bg-white rounded-lg shadow-lg p-6 w-80 z-10 relative">
            <button
              className="absolute top-2 right-2 bg-white"
              onClick={resetState}
            >
              &times;
            </button>
            <h2 className="text-center text-lg font-semibold mb-4">
              Confirm Rejection
            </h2>
            <p className="mb-4 text-left">
              Are you sure you want to reject this product?
            </p>
            <textarea
              className="w-full p-2 border rounded-lg bg-white text-left"
              placeholder="Enter comments here"
              value={popupComment}
              onChange={(e) => setPopupComment(e.target.value)}
            />
            <div className="flex justify-between mt-4">
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-full text-center"
                onClick={handleReject}
              >
                Yes, Reject
              </button>
              <button
                className="px-4 py-2 bg-gray-300 text-black rounded-full text-center"
                onClick={resetState}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Conditional Accept Alert Dialog */}
      {isConditionalAcceptAlertVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={resetState}
          />
          <div className="bg-white rounded-lg shadow-lg p-6 w-80 z-10 relative">
            <button
              className="absolute top-2 right-2 bg-white"
              onClick={resetState}
            >
              &times;
            </button>
            <h2 className="text-center text-lg font-semibold mb-4">
              Confirm Conditional Acceptance
            </h2>
            <p className="mb-4 text-left">
              Are you sure you want to conditionally accept this product?
            </p>
            <textarea
              className="w-full p-2 border rounded-lg bg-white text-left"
              placeholder="Enter comments here"
              value={popupComment}
              onChange={(e) => setPopupComment(e.target.value)}
            />
            <div className="flex justify-between mt-4">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-full text-center"
                onClick={handleConditionalAccept}
              >
                Yes, Conditional Accept
              </button>
              <button
                className="px-4 py-2 bg-gray-300 text-black rounded-full text-center"
                onClick={resetState}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
