import { useEffect, useState } from "react";
import { getBuybackItems, updateBuybackPaymentStatus } from "../api/AdminApi";

type PaymentMethod = "CASH" | "CREDIT_CARD" | "BANK_TRANSFER";

interface ProductPaymentItem {
  id: number;
  productName: string;
  customerName: string;
  buybackPrice: string;
  finalBuybackPrice: string;
  paymentStatus: string;
  paymentMethod: PaymentMethod; // Use PaymentMethod type here
  poid: string;
}

export function ProductPayment() {
  const [data, setData] = useState<ProductPaymentItem[]>([]);
  const [finalPrices, setFinalPrices] = useState<string[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    const fetchBuybackItems = async () => {
      try {
        const response = await getBuybackItems();
        const paymentData = response.data
          .filter((item) => item.buyback_status === "APPROVED")
          .map((item) => ({
            id: item.id,
            productName: item.product_name,
            customerName: `${item.customer_firstname} ${item.customer_lastname}`,
            buybackPrice: item.buyback_price.toString(),
            finalBuybackPrice: item.final_buyback_price ? item.final_buyback_price.toString() : "", 
            paymentStatus: item.payment_status,
            paymentMethod: (item.payment_method as PaymentMethod) || "",
            poid: item.po_id,
          }));

        setData(paymentData);
        setFinalPrices(paymentData.map((item) => item.finalBuybackPrice));
        setPaymentMethods(paymentData.map((item) => item.paymentMethod));
        setErrors(new Array(paymentData.length).fill(""));
      } catch (error) {
        console.error("Error fetching buyback items:", error);
      }
    };

    fetchBuybackItems();
  }, []);

  const handleFinalPriceChange = (index: number, value: string) => {
    const newPrices = [...finalPrices];
    newPrices[index] = value;
    setFinalPrices(newPrices);
  };

  const handlePaymentMethodChange = (index: number, value: string) => {
    const newMethods = [...paymentMethods];
    newMethods[index] = value as PaymentMethod; // Cast to PaymentMethod type
    setPaymentMethods(newMethods);
  };

  const validateFields = (index: number): boolean => {
    let errorMessage = "";
    if (!paymentMethods[index]) {
      errorMessage = "Please select a payment method.";
    } else if (!finalPrices[index] || parseFloat(finalPrices[index]) <= 0) {
      errorMessage = "Final buyback price must be greater than zero.";
    }

    const newErrors = [...errors];
    newErrors[index] = errorMessage;
    setErrors(newErrors);

    return !errorMessage;
  };

  const handleCompletePayment = async (id: number, index: number) => {
    if (!validateFields(index)) return;

    try {
      const finalBuybackPrice = finalPrices[index];
      const paymentMethod = paymentMethods[index];
      await updateBuybackPaymentStatus(
        id,
        finalBuybackPrice,
        "PAID",
        paymentMethod
      );

      setData((prevData) =>
        prevData.map((item, i) =>
          i === index ? { ...item, paymentStatus: "PAID", paymentMethod } : item
        )
      );
    } catch (error) {
      console.error("Error updating payment status:", error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "."
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Product Payment
      </h2>
      <table className="w-full">
        <thead>
          <tr className="bg-[#f78a8a] text-white">
            <th className="py-2 px-4 text-left">PRODUCT NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
            <th className="py-2 px-4 text-left">BUYBACK PRICE</th>
            <th className="py-2 px-4 text-left">FINAL BUYBACK PRICE</th>
            <th className="py-2 px-4 text-left">PO ID</th>
            <th className="py-2 px-4 text-left">PAYMENT STATUS</th>
            <th className="py-2 px-4 text-left">PAYMENT METHOD</th>
            <th className="py-2 px-4 text-left">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id} className="border-b">
              <td className="py-2 px-4">{item.productName}</td>
              <td className="py-2 px-4">{item.customerName}</td>
              <td className="py-2 px-4">{item.buybackPrice}</td>

              <td className="py-2 px-4">
                <input
                  type="text"
                  value={`${finalPrices[index]}`}
                  onChange={(e) =>
                    handleFinalPriceChange(
                      index,
                      e.target.value.replace(/^\$/, "")
                    )
                  }
                  onKeyDown={handleKeyDown}
                  className="w-full p-2 border rounded bg-white"
                  required
                  placeholder="Enter amount"
                />
              </td>
              <td className="py-2 px-4">{item.poid}</td>
              <td className="py-2 px-4">{item.paymentStatus}</td>
              <td className="py-2 px-4">
                <select
                  value={paymentMethods[index] || ""}
                  onChange={(e) =>
                    handlePaymentMethodChange(index, e.target.value)
                  }
                  className="w-full p-2 border rounded bg-white"
                >
                  <option value="">Select payment method</option>
                  <option value="CASH">Cash</option>
                  <option value="BANK_TRANSFER">Bank Transfer</option>
                  <option value="CREDIT_CARD">Credit Card</option>
                </select>
              </td>

              <td className="py-2 px-4">
                <button
                  onClick={() => handleCompletePayment(item.id, index)}
                  disabled={item.paymentStatus === "PAID"}
                  className={`px-4 py-2 rounded-full ${
                    item.paymentStatus === "PAID"
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-green-500 text-white hover:bg-green-600"
                  }`}
                >
                  COMPLETE PAYMENT
                </button>
                {errors[index] && (
                  <p className="text-red-500 text-sm mt-1">{errors[index]}</p>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
