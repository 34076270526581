import CaretCircleRight from "../../assets/sehaa_plp_clp/CaretCircleRight.svg";
import CaretCircleLeft from "../../assets/sehaa_plp_clp/CaretCircleLeft.svg";
import ProductCard from '../Cards/ProductCard';
import { Product } from '../../types/types';

// You might want to fetch this data from an API or pass it as props
const mockRelatedProducts: Product[] = [
];

const RelatedProducts: React.FC = () => {
  const handleProductClick = (product: Product) => {
    // Handle product click, e.g., navigate to product page
    console.log('Product clicked:', product);
  };

  return (
    <div className="w-full flex flex-col items-center justify-start gap-10 mb-4 px-4 lg:px-0">
      <div className="w-full flex flex-col items-start justify-start">
        <div className="relative font-semibold text-xl leading-none">
          Related Products
        </div>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 text-sm relative justify-items-center">
        {mockRelatedProducts.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            onClick={handleProductClick}
          />
        ))}
        {/* Caret Icons */}
        <img
          className="absolute top-[50%] left-[-40px] w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 cursor-pointer"
          alt=""
          src={CaretCircleLeft}
        />
        <img
          className="absolute top-[50%] right-[-40px] w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 cursor-pointer"
          alt=""
          src={CaretCircleRight}
        />
      </div>
    </div>
  );
};

export default RelatedProducts;
