import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import pageLeft from '../../assets/sehaa_plp_clp/page-left.svg';
import pageRight from '../../assets/sehaa_plp_clp/page-right.svg';
import CaretRight from '../../assets/sehaa_plp_clp/CaretRight.svg';
import { Product, Category, Pagination } from '../../types/types';
import { useStore } from '../../../context/StoreContext';
import ProductCard from './ProductCard';

interface CardsProps {
  products: Product[];
  category: Category | null;
  storeCode: string;
  pagination: Pagination;
  onPageChange: (page: number) => Promise<void>;
  subCategories: Category[];
  onSubCategoryChange: (subCategoryId: number) => Promise<void>;
  currentPage: number;
}

const Cards: React.FC<CardsProps> = ({
  products,
  category,
  pagination,
  onPageChange,
  subCategories,
  onSubCategoryChange,
}) => {
  const [filter, setFilter] = useState<string>('All Products');
  const { setSelectedProduct } = useStore();
  const [sortOption, setSortOption] = useState<string>('Name A to Z');

  const navigate = useNavigate();

  const handleClick = (product: Product) => {
    setSelectedProduct(product);
    navigate(`/product/${product.url_key}`);
  };

  const handleFilterChange = async (filterType: string, categoryId?: number) => {
    setFilter(filterType);
    if (filterType === 'All Products') {
      onPageChange(1);
    } else if (categoryId) {
      onSubCategoryChange(categoryId);
    }
  };

  const handleSortChange = (option: string) => {
    setSortOption(option);
    const sortedProducts = [...products];

    switch (option) {
      case 'Name A to Z':
        sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'Name Z to A':
        sortedProducts.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'Price low to high':
        sortedProducts.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
        break;
      case 'Price high to low':
        sortedProducts.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
        break;
    }

    // Note: You might want to update this to handle sorting through the parent component
    // if you want the sorting to persist across page changes
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pagination.total_pages; i++) {
      if (i === pagination.current_page) {
        pageNumbers.push(<span key={i} className="font-bold">{i}</span>);
      } else if (i === 1 || i === pagination.total_pages || (i >= pagination.current_page - 1 && i <= pagination.current_page + 1)) {
        pageNumbers.push(
          <span
            key={i}
            className="cursor-pointer"
            onClick={() => onPageChange(i)}
          >
            {i}
          </span>
        );
      } else if (i === pagination.current_page - 2 || i === pagination.current_page + 2) {
        pageNumbers.push(<span key={i}>...</span>);
      }
    }
    return pageNumbers;
  };

  const startItem = (pagination.current_page - 1) * pagination.page_size + 1;
  const endItem = Math.min(pagination.current_page * pagination.page_size, pagination.total_items);

  return (
    <div className="w-full cards-container mx-auto px-2 sm:px-4 md:px-6 lg:px-8">
      {/* Navbar */}
      <div className="w-full flex flex-col gap-4 mb-6 sm:mb-8">
        <div className="flex flex-col gap-3 sm:gap-4 w-full">
          <div className="flex flex-col gap-1 sm:gap-2 w-full">
            <div className="text-lg sm:text-xl md:text-2xl font-semibold leading-tight">
              {category?.name || 'All Products'}
            </div>
            <div className="flex items-center gap-1 text-xs text-gray-400">
              <div className="leading-tight">Home</div>
              <img className="w-2 h-2 sm:w-2.5 sm:h-2.5" alt="" src={CaretRight} />
              <div className="leading-tight">{category?.name || 'All Products'}</div>
            </div>
          </div>
          <div className="w-full border-b border-gray-200 overflow-x-auto scrollbar-hide">
            <div className="flex items-center gap-3 sm:gap-4 md:gap-6 lg:gap-8 text-center whitespace-nowrap">
              <div
                className={`flex items-center justify-center pb-2 cursor-pointer ${filter === 'All Products' ? 'border-b-[4px] border-primary font-bold' : ''}`}
                onClick={() => handleFilterChange('All Products')}
              >
                <div className="leading-6">All Products</div>
              </div>
              {subCategories.map(cat => (
                <div
                  key={cat.id}
                  className={`flex items-center justify-center pb-2 cursor-pointer ${filter === cat.name ? 'border-b-[4px] border-primary font-bold' : ''}`}
                  onClick={() => handleFilterChange(cat.name, cat.id)}
                >
                  <div className="leading-6">{cat.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col sm:flex-row justify-between items-start sm:items-center text-xs sm:text-sm gap-2">
          <div>
            Items {startItem}-{endItem} of {pagination.total_items} | Page {pagination.current_page} of {pagination.total_pages}
          </div>
          <div className="flex items-center gap-2 text-xs">
            Sort by:
            <select
              value={sortOption}
              onChange={(e) => handleSortChange(e.target.value)}
              className="border border-gray-300 bg-transparent rounded-md px-2 py-1"
            >
              <option>Name A to Z</option>
              <option>Name Z to A</option>
              <option>Price low to high</option>
              <option>Price high to low</option>
            </select>
          </div>
        </div>
      </div>

      {/* Cards Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} onClick={handleClick} />
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center gap-4 mt-6">
        <button
          className={`flex items-center justify-center p-2 border border-gray-300 rounded-full ${pagination.current_page === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
          onClick={() => onPageChange(pagination.current_page - 1)}
          disabled={pagination.current_page === 1}
        >
          <img className="w-4 h-4" alt="Previous" src={pageLeft} />
        </button>
        {renderPageNumbers()}
        <button
          className={`flex items-center justify-center p-2 border border-gray-300 rounded-full ${pagination.current_page === pagination.total_pages ? 'cursor-not-allowed opacity-50' : ''}`}
          onClick={() => onPageChange(pagination.current_page + 1)}
          disabled={pagination.current_page === pagination.total_pages}
        >
          <img className="w-4 h-4" alt="Next" src={pageRight} />
        </button>
      </div>
    </div>
  );
};

export default Cards;