import React, { useEffect, useState } from "react";
import HomeSidebar from "../components/HomeSidebar/Sidebar";
import Cards from "../components/Cards/Cards";
import { Product, Pagination, Category } from "../types/types";
import { useStore } from "../../context/StoreContext";
import { fetchProducts, fetchCategories } from "../api/MarketApiClient";

const ProductListingPage: React.FC = () => {
  const { selectedStore, selectedCategory, selectedFilters, setSelectedFilters } = useStore();
  const [products, setProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 1,
    page_size: 100,
    total_pages: 1,
    total_items: 0,
  });
  const [subCategories, setSubCategories] = useState<Category[]>([]);

  useEffect(() => {
    if (!selectedStore || !selectedCategory) return;

    // Reset current page when category or filters change
    setCurrentPage(1);

    const fetchFilteredProducts = async () => {
      const filters: Record<string, string | string[]> = {
        status: '1',
        visibility: '4',
        ...selectedFilters,
      };

      try {
        const fetchedData = await fetchProducts(
          selectedStore.code,
          selectedCategory.id,
          1, // Always fetch the first page when category or filters change
          filters
        );
        setProducts(fetchedData.products);
        setPagination(fetchedData.pagination);

        // Fetch subcategories
        const categoriesData = await fetchCategories(selectedStore.code);
        const selectedCategoryData = categoriesData.children_data.find(cat => cat.id === selectedCategory.id);
        if (selectedCategoryData) {
          const thirdLevelCategories = selectedCategoryData.children_data?.filter(cat => cat.level === 3) || [];
          setSubCategories(thirdLevelCategories);
        } else {
          setSubCategories([]);
        }
      } catch (error) {
        console.error("Error fetching products or categories:", error);
      }
    };

    fetchFilteredProducts();
  }, [selectedStore, selectedCategory, selectedFilters]);

  const updateFilters = (filters: { [attributeCode: string]: string[] }) => {
    setSelectedFilters(filters);
  };

  const handlePageChange = async (newPage: number) => {
    if (!selectedStore || !selectedCategory) return;

    setCurrentPage(newPage);

    const filters: Record<string, string | string[]> = {
      status: '1',
      visibility: '4',
      ...selectedFilters,
    };

    try {
      const fetchedData = await fetchProducts(
        selectedStore.code,
        selectedCategory.id,
        newPage,
        filters
      );
      setProducts(fetchedData.products);
      setPagination(fetchedData.pagination);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSubCategoryChange = async (subCategoryId: number) => {
    if (!selectedStore) return;

    setCurrentPage(1);

    const filters: Record<string, string | string[]> = {
      status: '1',
      visibility: '4',
      ...selectedFilters,
    };

    try {
      const fetchedData = await fetchProducts(
        selectedStore.code,
        subCategoryId,
        1,
        filters
      );
      setProducts(fetchedData.products);
      setPagination(fetchedData.pagination);
    } catch (error) {
      console.error("Error fetching subcategory products:", error);
    }
  };

  if (!selectedStore) return <div>Please select a store</div>;

  return (
    <div className="w-full">
      <div className="section w-full flex flex-col md:flex-row items-start justify-start gap-4 md:gap-10 lg:gap-12 px-4 md:px-8 lg:px-20 text-left text-xs md:text-sm lg:text-base">
        <HomeSidebar updateFilters={updateFilters} />
        <Cards 
          products={products} 
          category={selectedCategory} 
          storeCode={selectedStore.code} 
          pagination={pagination}
          onPageChange={handlePageChange}
          subCategories={subCategories}
          onSubCategoryChange={handleSubCategoryChange}
          currentPage={currentPage} 
        />
      </div>
    </div>
  );
};

export default ProductListingPage;