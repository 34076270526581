import React, { useState } from "react";
import { Product } from "../../types/types";
import { useStore } from "../../../context/StoreContext";
import {
  addProductToCart,
  fetchToken,
} from "../../api/MarketApiClient";  // Import the required API functions
import FilledStar from '../../assets/sehaa_plp_clp/Star.svg';
import EmptyStar from '../../assets/sehaa_plp_clp/EmptyStar.svg';
import Heart from '../../assets/sehaa_plp_clp/Heart.svg';
import LockKey from '../../assets/sehaa_plp_clp/LockKey.svg';
import Money from '../../assets/sehaa_plp_clp/Money.svg';
import PlusCircle from '../../assets/sehaa_plp_clp/PlusCircle.svg';
import MinusCircle from '../../assets/sehaa_plp_clp/MinusCircle.svg';
import Truck from '../../assets/sehaa_plp_clp/Truck.svg';
import CalendarCheck from '../../assets/sehaa_plp_clp/CalendarCheck.svg';
import CheckCircle from '../../assets/sehaa_plp_clp/CheckCircle.svg';
import { CartResponse, ProductInCart } from '../../types/types';
import HtmlInputView from '../Utils/HtmlInputView';

interface ProductDetailsProps {
  product: Product | null;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ product }) => {
  const [quantity, setQuantity] = useState(1); // State to track quantity
  const [isAdding, setIsAdding] = useState(false); // State to track if adding to cart
  const [error, setError] = useState<string | null>(null); // Error state

  const { user, setProductsInCart, productsInCart, cartDetails, setCartDetails } = useStore();

  if (!product) {
    return <div>No product details available</div>;
  }

  const {
    name,
    brand,
    price_with_tax,
    special_price_with_tax,
    reviews_count,
    average_rating,
    description,
    in_stock,
    day_to_deliver,
    sku, 
  } = product;

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : prevQuantity));
  };

  const handleAddToCart = async () => {
    setIsAdding(true);
    setError(null);
    
    try {
      if (!user || !user.email) {
        throw new Error("User not logged in.");
      }

      const token = await fetchToken(user.email);
      if (!token) {
        throw new Error("Failed to fetch token. Please login.");
      }

      const productInCart: ProductInCart = await addProductToCart(sku, quantity, token);
      setProductsInCart([...productsInCart, productInCart]);

      // Ensure cartDetails is initialized if it's null
      const newCartDetails: CartResponse = {
        id: cartDetails?.id || 1, // Provide a default or fetched ID
        created_at: cartDetails?.created_at || new Date().toISOString(),
        updated_at: new Date().toISOString(),
        is_active: true,
        is_virtual: false, // Set a default value if not applicable
        items: [...(cartDetails?.items || []), productInCart],
        items_count: (cartDetails?.items_count || 0) + 1,
        items_qty: (cartDetails?.items_qty || 0) + quantity,
        customer: cartDetails?.customer || {
          id: 0,
          group_id: 0,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          created_in: '',
          email: user.email,
          firstname: '',
          lastname: '',
          store_id: 0,
          website_id: 0,
          addresses: [],
          disable_auto_group_change: 0,
          extension_attributes: {
            is_subscribed: false,
          },
        },
        billing_address: cartDetails?.billing_address || {
          id: 0,
          region: null,
          region_id: null,
          region_code: null,
          country_id: null,
          street: [''],
          telephone: null,
          postcode: null,
          city: null,
          firstname: null,
          lastname: null,
          customer_id: 0,
          email: user.email,
          same_as_billing: 0,
          save_in_address_book: 0,
        },
        orig_order_id: 0,
        currency: {
          global_currency_code: 'USD',
          base_currency_code: 'USD',
          store_currency_code: 'USD',
          quote_currency_code: 'USD',
          store_to_base_rate: 1,
          store_to_quote_rate: 1,
          base_to_global_rate: 1,
          base_to_quote_rate: 1,
        },
        customer_is_guest: false,
        customer_note_notify: false,
        customer_tax_class_id: 0,
        store_id: 0,
        extension_attributes: {
          shipping_assignments: [],
        },
      };

      setCartDetails(newCartDetails);
    } catch (error) {
      console.error("Error adding product to cart:", error);
      setError("Failed to add product to cart. Please try again.");
    } finally {
      setIsAdding(false);
    }
  };
  
  return (
    <div className="flex flex-col items-start justify-start gap-5 md:gap-8 lg:gap-10">
      <div className="w-full flex flex-col items-start justify-start gap-2">
        <div className="text-2xl lg:text-3xl font-semibold text-left">{name}</div>
        <div className="flex items-start justify-between w-full gap-2 text-xs md:text-sm lg:text-base">
          <div className="font-semibold">
            Brand: {brand || "No brand specified"}
          </div>
          <div className="flex items-center justify-center gap-1 text-green-500">
            <img
              className="w-5 h-5"
              alt="Stock availability"
              src={in_stock ? CheckCircle : EmptyStar}
            />
            <div className="font-semibold">
              {in_stock ? "Available in Stock" : "Out of Stock"}
            </div>
          </div>
          <div className="flex items-center justify-center gap-1">
            <img className="w-5 h-5" alt="Add to wishlist" src={Heart} />
            <div className="font-semibold">Add to wishlist</div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-start justify-start gap-2">
        <div className="flex items-end justify-start gap-4">
          <b className="text-lg md:text-4xl font-bold">
            AED {special_price_with_tax !== null ? special_price_with_tax : price_with_tax}
          </b>
        </div>

        <div className="flex items-center justify-start gap-2 text-sm md:text-base">
          <div className="flex flex-row items-center gap-0.5">
            {[...Array(5)].map((_, index) => {
              const filledStars = Math.round(average_rating / 20);
              return (
                <img
                  key={index}
                  className="w-2 h-2 sm:w-2.5 sm:h-2.5 md:w-3 md:h-3"
                  alt="Star"
                  src={index < filledStars ? FilledStar : EmptyStar}
                />
              );
            })}
          </div>
          <div>{reviews_count} Reviews</div>
        </div>
      </div>

      <div className="w-full text-sm text-left">
        <HtmlInputView content={description} />
      </div>

      <div className="w-full flex justify-between text-center">
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-full bg-gray-100 p-2">
            <img className="w-5 h-5" alt="Calendar" src={CalendarCheck} />
          </div>
          <p className="text-sm">{day_to_deliver || "No delivery information"}</p>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-full bg-gray-100 p-2">
            <img className="w-5 h-5" alt="Truck" src={Truck} />
          </div>
          <p className="text-sm">Free Delivery in Dubai</p>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-full bg-gray-100 p-2">
            <img className="w-5 h-5" alt="Money" src={Money} />
          </div>
          <p className="text-sm">Cash on Delivery</p>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-full bg-gray-100 p-2">
            <img className="w-5 h-5" alt="Secure Transaction" src={LockKey} />
          </div>
          <p className="text-sm">Secure Transaction</p>
        </div>
      </div>

      <div className="w-full bg-gray-100 rounded-lg p-5 flex flex-col gap-4">
        <div className="flex items-center gap-2 text-gray-500">
          <div className="w-10 h-10 rounded-full bg-gray-500"></div>
          <div className="flex flex-col">
            <div className="text-sm font-semibold">Selected color</div>
            <div className="text-lg font-semibold text-black">Space grey</div>
          </div>
        </div>
        <div className="w-full h-[1px] bg-gray-300"></div>
        <div className="flex flex-col gap-2 text-sm">
          <div className="font-semibold">Choose your color</div>
          <div className="flex gap-2">
            <div className="w-7 h-7 rounded-full bg-black"></div>
            <div className="w-7 h-7 rounded-full bg-red-700"></div>
            <div className="w-7 h-7 rounded-full bg-blue-600"></div>
            <div className="w-7 h-7 rounded-full bg-teal-400"></div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex items-center justify-between gap-2 bg-white rounded-lg p-2 w-full">
            <img
              className="w-8 h-8 cursor-pointer"
              alt="Minus quantity"
              src={MinusCircle}
              onClick={handleDecrease}
            />
            <b>{quantity}</b>
            <img
              className="w-8 h-8 cursor-pointer"
              alt="Add quantity"
              src={PlusCircle}
              onClick={handleIncrease}
            />
          </div>
          <button
            className="flex items-center justify-center gap-2 text-white bg-green-500 rounded-lg p-2 w-full"
            onClick={handleAddToCart}
            disabled={isAdding}
          >
            {isAdding ? 'Adding...' : 'Add to Cart'}
          </button>
        </div>
        {error && <div className="text-red-500 text-sm">{error}</div>}
      </div>
    </div>
  );
};

export default ProductDetails;
