import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { StoreProvider } from "./context/StoreContext";
import { BuybackProvider } from "./context/BuybackContext"; // Add BuybackContext here
import HomePage from "./market/pages/HomePage";
import ProductDetailsPage from "./market/pages/ProductDetailsPage";
import NavbarOptions from "./market/components/NavbarOptions/NavbarOptions";
import Header from "./market/components/Header/Header";
import Navbar from "./market/components/Navbar/Navbar";
import Footer from "./market/components/Footer/Footer";
import ProductListingPage from "./market/pages/ProductListingPage";
import BuybackAdminScreen from "./admin/pages/BuybackScreen";
import AuthComponent from "./auth/AuthComponent";
import BuybackFormPage from "./market/pages/BuybackFormPage";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { route } = useAuthenticator((context) => [context.route]);

  if (route !== "authenticated") {
    return <AuthComponent>{children}</AuthComponent>;
  }

  return <>{children}</>;
};

const MainLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <Header />
    <Navbar />
    <NavbarOptions />
    {children}
    <Footer />
  </>
);

const BuybackScreen = ({ children }: { children: React.ReactNode }) => (
  <div className="">{children}</div>
);

const BuybackForm = ({ children }: { children: React.ReactNode }) => (
  <div className="">{children}</div>
);

export function App() {
  return (
    <StoreProvider>
      <BuybackProvider> {/* Wrap the app with both StoreProvider and BuybackProvider */}
        <div className="relative bg-white gap-12 min-h-screen text-xs text-gray-800 font-sora">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainLayout><HomePage /></MainLayout>} />
              <Route path="/categories/:categoryName" element={<MainLayout><ProductListingPage /></MainLayout>} />
              <Route
                path="/product/:urlKey"
                element={
                  <MainLayout>
                    <ProtectedRoute>
                      <ProductDetailsPage />
                    </ProtectedRoute>
                  </MainLayout>
                }
              />
              <Route
                path="/admin/buyback"
                element={
                  <BuybackScreen>
                    <ProtectedRoute>
                      <BuybackAdminScreen />
                    </ProtectedRoute>
                  </BuybackScreen>
                }
              />
              <Route
                path="/market/form/:itemId"
                element={
                  <BuybackForm>
                    <ProtectedRoute>
                      <BuybackFormPage />
                    </ProtectedRoute>
                  </BuybackForm>
                }
              />
            </Routes>
          </BrowserRouter>
        </div>
      </BuybackProvider>
    </StoreProvider>
  );
}

export default App;
