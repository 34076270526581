import React, { useState, FormEvent } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { postBuybackReview } from "../../../admin/api/AdminApi"; // Import the API

interface BuybackFormProps {
  itemId: number;
  productName: string;
  purchaseDate: Date;
}

const BuybackForm: React.FC<BuybackFormProps> = ({
  itemId,
  productName,
  purchaseDate,
}) => {
  // const location = useLocation();
  //const { itemId, productName, purchaseDate } = location.state || {};
  const [comments, setComments] = useState("");
  const [pickupDate, setPickupDate] = useState<Date | null>(null);
  const [altPhone, setAltPhone] = useState("");
  const [address, setAddress] = useState("");
  const [dropOffStore, setDropOffStore] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [image1, setImage1] = useState<File | null>(null);
  const [image2, setImage2] = useState<File | null>(null);
  const [image3, setImage3] = useState<File | null>(null);
  const [showTerms, setShowTerms] = useState(false); // State for toggling terms visibility

  const navigate = useNavigate();

  // const itemId = 10;

  // Helper to format date to 'YYYY-MM-DD HH:mm:ss.SS'
  const formatDateTime = (date: Date) => {
    const pad = (n: number) => (n < 10 ? "0" + n : n);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    //const hours = pad(date.getHours());
    // const minutes = pad(date.getMinutes());
    // const seconds = pad(date.getSeconds());
    // const milliseconds = String(date.getMilliseconds())
    //   .padStart(2, "0")
    //   .slice(0, 2); // For two decimal places
    // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    return `${year}-${month}-${day}`;
  };

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const reviewData = {
      customerComments: comments,
      csReviewerId: 1,
      csReviewDate: formatDateTime(new Date()),
      customerAddress: address,
      alternatePhoneNo: altPhone,
      paymentMethod: paymentMode,
    };

    try {
      await postBuybackReview(itemId, reviewData);
      alert("Buyback Form submitted successfully!");

      // Reset form fields after successful submission
      setComments("");
      setPickupDate(null);
      setAltPhone("");
      setAddress("");
      setDropOffStore(false);
      setPaymentMode("");
      navigate(`/market/form/${itemId}`);
    } catch (error) {
      console.error("Error submitting buyback request:", error);
      alert("An error occurred while submitting the request.");
    }
  };

  return (
    <div className="sm:ml-10 lg:ml-20 min-h-screen font-sans">
      <div className="container sm:px-5 lg:px-80 py-10">
        <h1 className="text-3xl font-bold text-center text-red-400 mb-8">
          Buyback Form
        </h1>

        <div className="bg-white text-black rounded-lg shadow-lg p-8 mb-8">
          <h2 className="text-2xl font-bold text-red-400 mb-4">
            Product Information
          </h2>
          <p className="mb-2">
            <strong>Product Name:</strong> {productName || "N/A"}
          </p>
          <p>
            <strong>Purchase Date:</strong>{" "}
            {purchaseDate ? formatDateTime(new Date(purchaseDate)) : "N/A"}
          </p>
          <button
          type="button"
          className="text-blue-500 mt-4 border-black bg-white text-sm"
          onClick={() => setShowTerms(!showTerms)} // Toggle visibility on click
        >
          Buyback Guarantee Terms and Conditions
        </button>

        {showTerms && (
          <div className="mt-4 text-sm text-gray-700">
            <p>
              <strong>Eligibility:</strong> Sehaa Buyback Guarantee applies to
              all high-value Rehamo products purchased from our website.
            </p>
            <br />
            <p>
              <strong>Buyback Period:</strong> The duration eligible for Sehaa
              Buyback Guarantee varies by product category. For example, beds
              may qualify for up to a 1-year Buyback period, while walkers may
              qualify for up to 6 months.
            </p>
            <br/>
            <p>
              <strong>Process:</strong> To initiate the buyback, visit our
              website, provide your details, upload product images, and submit.
              Your information will be forwarded to our logistics, warehouse,
              and accounts teams automatically.
            </p> <br />
            <p>
              <strong>Collection Fee:</strong> If the product pick-up is
              required outside Dubai or Sharjah, a collection fee will apply.
              The fee varies based on the product and location. This fee will be
              applied based on your selection and location input during the
              process.
            </p>
            <br />
            <p>
              <strong>Price Determination:</strong> The Buyback price is
              determined based on the product category and the duration of the
              Buyback period.
            </p>
          </div>
        )}
        </div>
        <div className="bg-white text-black rounded-lg shadow-lg p-8">
          <div className="mb-6">
            <label
              htmlFor="image1"
              className="block mb-2 text-sm font-semibold text-gray-700 uppercase"
            >
              Upload Image 1:
            </label>
            <div className="flex items-center">
              <input
                type="file"
                id="image1"
                name="image1" // Add name attribute here
                accept=".png"
                required
                className="hidden"
                onChange={(e) => handleImageChange(e, setImage1)}
              />
              <label
                htmlFor="image1"
                className="bg-blue-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-blue-600 transition duration-300 text-sm font-semibold uppercase"
              >
                Choose File
              </label>
              <span className="ml-4 text-sm">
                {image1 ? image1.name : "No file chosen"}
              </span>
            </div>
          </div>

          <div className="mb-6">
            <label
              htmlFor="image2"
              className="block mb-2 text-sm font-semibold text-gray-700 uppercase"
            >
              Upload Image 2:
            </label>
            <div className="flex items-center">
              <input
                type="file"
                id="image2"
                name="image2"
                accept=".png"
                required
                className="hidden"
                onChange={(e) => handleImageChange(e, setImage2)}
              />
              <label
                htmlFor="image2"
                className="bg-blue-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-blue-600 transition duration-300 text-sm font-semibold uppercase"
              >
                Choose File
              </label>
              <span className="ml-4 text-sm">
                {image2 ? image2.name : "No file chosen"}
              </span>
            </div>
          </div>

          <div className="mb-6">
            <label
              htmlFor="image3"
              className="block mb-2 text-sm font-semibold text-gray-700 uppercase"
            >
              Upload Image 3:
            </label>
            <div className="flex items-center">
              <input
                type="file"
                id="image3"
                name="image3" // Add name attribute here
                accept=".png"
                required
                className="hidden"
                onChange={(e) => handleImageChange(e, setImage3)}
              />
              <label
                htmlFor="image3"
                className="bg-blue-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-blue-600 transition duration-300 text-sm font-semibold uppercase"
              >
                Choose File
              </label>
              <span className="ml-4 text-sm">
                {image3 ? image3.name : "No file chosen"}
              </span>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {/* Image Upload Section */}

            {/* Existing Form Fields */}
            <div className="mb-6">
              <label
                htmlFor="comments"
                className="block mb-2 text-sm font-semibold text-gray-700 uppercase"
              >
                Product Quality and Repair Comments:
              </label>
              <textarea
                id="comments"
                rows={4}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-red-400"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              ></textarea>
            </div>

            <div className="mb-6">
              <label
                htmlFor="pickupDate"
                className="block mb-2 text-sm font-semibold text-gray-700 uppercase"
              >
                Desired Pickup Date & Time:
              </label>
              <DatePicker
                selected={pickupDate}
                onChange={(date) => setPickupDate(date)}
                showTimeSelect
                dateFormat="Pp"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none bg-white focus:ring-2 focus:ring-red-400"
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="altPhone"
                className="block mb-2 text-sm font-semibold text-gray-700 uppercase"
              >
                Alternate Phone Number:
              </label>
              <input
                type="tel"
                id="altPhone"
                required
                className="w-full px-4 py-2 border border-gray-300 bg-white rounded-lg focus:outline-none focus:ring-2 focus:ring-red-400"
                value={altPhone}
                onChange={(e) => setAltPhone(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="address"
                className="block mb-2 text-sm font-semibold text-gray-700 uppercase"
              >
                Pickup Address:
              </label>
              <textarea
                id="address"
                rows={3}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-red-400"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></textarea>
            </div>

            <div className="mb-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox bg-white text-red-400"
                  checked={dropOffStore}
                  onChange={(e) => setDropOffStore(e.target.checked)}
                />
                <span className="ml-2">I prefer to drop off at the store</span>
              </label>
            </div>

            <div className="mb-6">
              <label
                htmlFor="paymentMode"
                className="block mb-2 text-sm font-semibold text-gray-700 uppercase"
              >
                Payment Mode:
              </label>
              <select
                id="paymentMode"
                required
                className="w-full px-4 py-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-400"
                value={paymentMode}
                onChange={(e) => setPaymentMode(e.target.value)}
              >
                <option value="">Select payment mode</option>
                <option value="CASH">Cash on Receiving</option>
                <option value="CREDIT_CARD">Credit Card</option>
                <option value="BANK_TRANSFER">Bank Transfer</option>
              </select>
            </div>

            <button
              type="submit"
              className="w-full bg-red-400 text-white py-3 px-6 rounded-full font-semibold uppercase tracking-wide hover:bg-red-500 transition duration-300 transform hover:-translate-y-1 hover:shadow-lg"
            >
              Submit Buyback Request
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BuybackForm;
