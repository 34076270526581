import React from "react";
import dummyHome from "../assets/sehaa_plp_clp/dummy-home.png";

const HomePage: React.FC = () => {
    return (
        <div className="flex justify-center items-center min-h-screen mx-[10%] mb-[100px]">
            <img src={dummyHome} alt="Dummy Home" className="w-full h-full"/>
        </div>
    );
};

export default HomePage;
