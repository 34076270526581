import axios from "axios";
import {
  BuybackResponse,
  BuybackReview,
  BuybackReviewResponse,
} from "../types/types";

const API_BASE_URL =
  import.meta.env.VITE_API_BASE_URL || "http://localhost:8000";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

// Function to fetch buyback items
const getBuybackItems = async (page: number = 1): Promise<BuybackResponse> => {
  try {
    const response = await apiClient.get<BuybackResponse>(`/admin/buyback`, {
      params: {
        page, // Pagination parameter, if needed
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback items:", error);
    throw error;
  }
};

// Function to update a buyback item
const updateBuybackItem = async (id: number, data: object) => {
  try {
    const response = await apiClient.put(`/admin/buyback/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating buyback item:", error);
    throw error;
  }
};

const updateInventory = async (id: number, data: object) => {
  try {
    const response = await apiClient.put(`/admin/buyback/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating buyback item:", error);
    throw error;
  }
};

// Function to delete a buyback item
const deleteBuybackItem = async (id: number) => {
  try {
    const response = await apiClient.delete(`/admin/buyback/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting buyback item:", error);
    throw error;
  }
};

// Function to post a review for a buyback item
const postBuybackReview = async (
  id: number,
  reviewData: Omit<
    BuybackReview,
    "csReviewStatus" | "inspectionStatus" | "inspectionNotes"
  >
) => {
  try {
    const defaultData = {
      csReviewStatus: "PENDING",
      inspectionStatus: "PENDING",
      inspectionNotes: "Needs further inspection",
    };

    const response = await apiClient.post(`/admin/buyback/${id}/reviews`, {
      ...defaultData,
      ...reviewData, // Merge user-provided review data with default values
    });

    return response.data;
  } catch (error) {
    console.error("Error posting buyback review:", error);
    throw error;
  }
};

const getBuybackReviews = async (): Promise<BuybackReviewResponse> => {
  try {
    const response = await apiClient.get<BuybackReviewResponse>(
      "/admin/buyback/reviews"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback reviews:", error);
    throw error;
  }
};

// Function to update a review for a buyback item
const updateBuybackReview = async (
  itemId: number,
  reviewId: number,
  reviewData: Pick<BuybackReview, "csReviewStatus">
) => {
  try {
    // Construct the URL
    const url = `/admin/buyback/${itemId}/reviews/${reviewId}`;

    const response = await apiClient.put(url, reviewData);
    return response.data;
  } catch (error) {
    console.error("Error updating buyback review:", error);
    throw error;
  }
};

const updateProductInspection = async (
  itemId: number,
  reviewId: number,
  reviewData: Pick<BuybackReview, "inspectionStatus" | "inspectionNotes"> & {
    customerComments: string;
    csReviewStatus: string;
    csReviewerId: number;
    productCondition: 'IN_GOOD_SHAPE' | 'REQUIRE_REPAIR';
    inventoryType: "USED_PRODUCT" | "RENTAL_PRODUCT";
  }
) => {
  try {
    // Construct the URL
    const url = `/admin/buyback/${itemId}/reviews/${reviewId}`;

    const response = await apiClient.put(url, reviewData);
    return response.data;
  } catch (error) {
    console.error("Error updating buyback review:", error);
    throw error;
  }
};

// Function to update payment status and final price of a buyback item
const updateBuybackPaymentStatus = async (
  id: number,
  finalBuybackPrice: string,
  paymentStatus: string,
  paymentMethod: 'CASH' | 'CREDIT_CARD' | 'BANK_TRANSFER' 
) => {
  try {
    const response = await apiClient.put(`/admin/buyback/${id}`, {
      finalBuybackPrice,
      paymentStatus,
      paymentMethod, // Include paymentMethod in the request body
    });
    return response.data;
  } catch (error) {
    console.error("Error updating buyback payment status:", error);
    throw error;
  }
};


export {
  getBuybackItems,
  updateBuybackItem,
  deleteBuybackItem,
  postBuybackReview,
  getBuybackReviews,
  updateBuybackReview,
  updateProductInspection,
  updateBuybackPaymentStatus,
  updateInventory,
};
