import React, { useEffect, useState } from "react";
import { getBuybackReviews, getBuybackItems,  updateProductInspection } from "../api/AdminApi";
import { BuybackReviewTable } from "../types/types";

interface ProductInspectionItem {
  productName: string;
  customerName: string;
  customerComments: string;
  csDecision: string;
  productSKU: string;
  id: number;
  reviewId: number;
  inspectionStatus: "PENDING" | "ACCEPTED" | "REJECTED";
  inspectionNotes: string;
  productCondition?: "IN_GOOD_SHAPE" | "REQUIRE_REPAIR"; // Optional
  inventoryType?: "USED_PRODUCT" | "RENTAL_PRODUCT"; // Optional
}

export function ProductInspection() {
  const [data, setData] = useState<ProductInspectionItem[]>([]);
  const [repairComments, setRepairComments] = useState<{
    [key: number]: string;
  }>({});
  const [selectedCondition, setSelectedCondition] = useState<{
    [key: number]: string;
  }>({});
  const [selectedInventoryType, setSelectedInventoryType] = useState<{
    [key: number]: "USED_PRODUCT" | "RENTAL_PRODUCT";
  }>({});
  const [errorMessages, setErrorMessages] = useState<{ [key: number]: string }>(
    {}
  );

  useEffect(() => {
    const fetchBuybackData = async () => {
      try {
        // Fetch reviews and items data
        const reviewsResponse = await getBuybackReviews();
        const itemsResponse = await getBuybackItems();

        // Transform the data to match the required structure
        const transformedData: ProductInspectionItem[] = reviewsResponse.data
          .filter(
            (item: BuybackReviewTable) =>
              item.cs_review_status === "ACCEPTED" ||
              item.cs_review_status === "CONDITIONALLY_ACCEPTED"
          )
          .map((item: BuybackReviewTable) => ({
            productName: item.product_name,
            customerName: `${item.customer_firstname} ${item.customer_lastname}`,
            customerComments: item.customer_comments,
            csDecision: item.cs_review_status,
            productSKU: item.product_sku,
            id: item.item_id,
            reviewId: item.id,
            inspectionStatus: item.inspection_status || "PENDING",
            inspectionNotes: item.inspection_notes || "",
            productCondition: item.product_condition,
            inventoryType: undefined, // Will be populated from items data
          }));

        // Map inventoryType from getBuybackItems to corresponding review items
        transformedData.forEach((dataItem) => {
          const matchingItem = itemsResponse.data.find((item) => item.id === dataItem.id);
          if (matchingItem) {
            dataItem.inventoryType = matchingItem.inventory_type;
          }
        });

        setData(transformedData);

        // Initialize condition and inventoryType selections
        const initialCondition: { [key: number]: string } = {};
        const initialInventoryType: { [key: number]: "USED_PRODUCT" | "RENTAL_PRODUCT" } = {};

        transformedData.forEach((item, index) => {
          if (item.productCondition) {
            initialCondition[index] = item.productCondition;
          }
          if (item.inventoryType) {
            initialInventoryType[index] = item.inventoryType;
          }
        });

        setSelectedCondition(initialCondition);
        setSelectedInventoryType(initialInventoryType);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBuybackData();
  }, []);
  const handleConditionChange = (
    index: number,
    value: "IN_GOOD_SHAPE" | "REQUIRE_REPAIR"
  ) => {
    setSelectedCondition((prevState) => ({
      ...prevState,
      [index]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [index]: "" }));
  };

  const handleInventoryTypeChange = (
    index: number,
    value: "USED_PRODUCT" | "RENTAL_PRODUCT"
  ) => {
    setSelectedInventoryType((prevState) => ({
      ...prevState,
      [index]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [index]: "" }));
  };

  const handleRepairCommentChange = (index: number, value: string) => {
    setRepairComments((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleConfirmInspection = async (
    item: ProductInspectionItem,
    index: number
  ) => {
    if (!selectedCondition[index]) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [index]: "Please select a Condition.",
      }));
      return;
    }
    if (!selectedInventoryType[index]) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [index]: "Please select an Inventory Type.",
      }));
      return;
    }

    try {
      await updateProductInspection(item.id, item.reviewId, {
        inspectionStatus: "ACCEPTED",
        inspectionNotes: repairComments[index] || "",
        customerComments: item.customerComments,
        csReviewStatus: item.csDecision,
        csReviewerId: 1,
        productCondition: selectedCondition[index] as
          | "IN_GOOD_SHAPE"
          | "REQUIRE_REPAIR",
        inventoryType: selectedInventoryType[index],
      });

      setData((prevData) =>
        prevData.map((dataItem) =>
          dataItem.id === item.id
            ? { ...dataItem, inspectionStatus: "ACCEPTED" }
            : dataItem
        )
      );

      alert(`Inspection for ${item.productName} has been marked as COMPLETED.`);
    } catch (error) {
      console.error("Failed to update inspection status:", error);
      alert("Failed to update inspection status.");
    }
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Product Inspection
      </h2>
      <table className="w-full">
        <thead>
          <tr className="bg-[#f78a8a] text-white">
            <th className="py-2 px-4 text-left">PRODUCT NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER COMMENTS</th>
            <th className="py-2 px-4 text-left">CS DECISION</th>
            <th className="py-2 px-4 text-left">PRODUCT SKU</th>
            <th className="py-2 px-4 text-left">INVENTORY TYPE</th>
            <th className="py-2 px-4 text-left">CONDITION</th>
            <th className="py-2 px-4 text-left">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr className="border-b">
                <td className="py-2 px-4">{item.productName}</td>
                <td className="py-2 px-4">{item.customerName}</td>
                <td className="py-2 px-4">{item.customerComments}</td>
                <td className="py-2 px-4">{item.csDecision}</td>
                <td className="py-2 px-4">
                  <input
                    type="text"
                    placeholder="Enter SKU"
                    className="w-full p-2 border rounded bg-white"
                    value={item.productSKU}
                    readOnly
                  />
                </td>
                <td className="py-2 px-4">
                  <select
                    className="w-full p-2 border rounded bg-white"
                    value={
                      selectedInventoryType[index] || item.inventoryType || ""
                    }
                    onChange={(e) =>
                      handleInventoryTypeChange(
                        index,
                        e.target.value as "USED_PRODUCT" | "RENTAL_PRODUCT"
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="USED_PRODUCT">Used Product</option>
                    <option value="RENTAL_PRODUCT">Rental Product</option>
                  </select>
                  {!selectedInventoryType[index] && !item.inventoryType && (
                    <p className="text-red-500 text-sm mt-1">
                      {errorMessages[index]}
                    </p>
                  )}
                </td>

                <td className="py-2 px-4">
                  <select
                    className="w-full p-2 border rounded bg-white"
                    value={selectedCondition[index] || ""}
                    onChange={(e) =>
                      handleConditionChange(
                        index,
                        e.target.value as "IN_GOOD_SHAPE" | "REQUIRE_REPAIR"
                      )
                    }
                  >
                    <option value="">Select condition</option>
                    <option value="IN_GOOD_SHAPE">In Good Shape</option>
                    <option value="REQUIRE_REPAIR">Require Repair</option>
                  </select>
                  {!selectedCondition[index] && (
                    <p className="text-red-500 text-sm mt-1">
                      {errorMessages[index]}
                    </p>
                  )}
                </td>
                <td className="py-2 px-4">
                  <button
                    className={`px-4 py-2 rounded-full ${
                      item.inspectionStatus === "ACCEPTED"
                        ? "bg-gray-300 text-black"
                        : "bg-green-500 text-white"
                    }`}
                    onClick={() => handleConfirmInspection(item, index)}
                    disabled={item.inspectionStatus === "ACCEPTED"}
                  >
                    {item.inspectionStatus === "ACCEPTED"
                      ? "Completed"
                      : "Confirm"}
                  </button>
                </td>
              </tr>
              {selectedCondition[index] === "REQUIRE_REPAIR" && (
                <tr className="border-b">
                  <td colSpan={8} className="py-2 px-4">
                    <textarea
                      className="w-full p-2 border rounded bg-white"
                      placeholder="Add repair comments"
                      value={repairComments[index] || ""}
                      onChange={(e) =>
                        handleRepairCommentChange(index, e.target.value)
                      }
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}