import React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { decode } from 'he';

interface HtmlInputViewProps {
  content: string;
}

const HtmlInputView: React.FC<HtmlInputViewProps> = ({ content }) => {

  const decodedContent = decode(content);
  const sanitizedContent = DOMPurify.sanitize(decodedContent);
  
  const options = {
    replace: (domNode: any) => {
      if (domNode.type === 'tag') {
        switch (domNode.name) {
          case 'h1':
            domNode.attribs.class = 'text-xl font-bold mb-4';
            break;
          case 'h2':
            domNode.attribs.class = 'text-l font-bold mb-4';
            break;
          case 'h3':
              domNode.attribs.class = 'text-m font-bold mb-4';
              break;
          case 'ul':
            domNode.attribs.class = 'list-disc list-inside mb-4';
            break;
          case 'li':
            domNode.attribs.class = 'mb-2';
            break;
          case 'strong':
            domNode.attribs.class = 'font-bold';
            break;
          case 'p':
            domNode.attribs.class = 'mb-4';
            break;
          default:
            domNode.attribs.class = 'mb-4';
            break;
        }
      }
    }
  };

  return (
    <div className="formatted-description text-sm">
      {parse(sanitizedContent, options)}
    </div>
  );
};

export default HtmlInputView;