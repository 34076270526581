import React, { useState } from 'react';
import Stethoscope from '../../assets/sehaa_plp_clp/Stethoscope.svg';
import ArrowsOut from '../../assets/sehaa_plp_clp/ArrowsOut.svg';
import SealQuestion from '../../assets/sehaa_plp_clp/SealQuestion.svg';
import Star from '../../assets/sehaa_plp_clp/Star.png';
import ClipboardText from '../../assets/sehaa_plp_clp/ClipboardText.svg';
import ListMagnifyingGlass from '../../assets/sehaa_plp_clp/ListMagnifyingGlass.svg'

import HtmlInputView from '../Utils/HtmlInputView';

export interface Review {
  review_id: string;
  nickname: string;
  summary: string;
  description: string;
  rating_value: { rating_star: number; rating_percent: string }[];
  posted_date: string;
}

interface Tab {
  id: string;
  label: string;
  icon: string;
  content: React.ReactNode;
}

interface ProductDescriptionProps {
  description: string;
  knowYourSize: string;
  medicalDetail: string;
  legalDisclaimer: string;
  reviewsDetail: Review[];
  reviewsCount: number;
  faqs: string;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({
  description,
  knowYourSize,
  medicalDetail,
  legalDisclaimer,
  reviewsDetail,
  faqs
}) => {
  const [activeTab, setActiveTab] = useState<string>("details");
  const [rating, setRating] = useState<number>(0);
  const [hover, setHover] = useState<number>(0);

  // Render review section for the "Reviews" tab
  const renderReviewSection = () => (
    <div className="flex flex-col md:flex-row gap-8 p-6 max-w-4xl mx-auto">
      <div className="flex-1">
        <div className="mb-6">
          <span className="text-6xl font-bold text-gray-300">
            5
          </span>
          <span className="text-3xl font-bold text-gray-700">/5</span>
          <p className="text-xl font-semibold text-gray-700">Overall Rating</p>
        </div>
        <div className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Customer Reviews
          </h2>
          {reviewsDetail.map((review) => (
            <div key={review.review_id} className="border-b pb-4 mb-4">
              <div className="flex items-center mb-2">
                <span className="font-semibold text-gray-700 mr-2">
                  {review.nickname}
                </span>
                <div className="flex">
                  {review.rating_value.map(({ rating_star }) =>
                    Array.from({ length: 5 }).map((_, idx) => (
                      <svg
                        key={idx}
                        className={`w-4 h-4 ${
                          idx < rating_star
                            ? "text-yellow-400"
                            : "text-gray-300"
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 15.273l5.29 3.107-1.419-6.053L18 8.574l-6.262-.545L10 2 8.262 8.029 2 8.574l4.309 3.753-1.419 6.053L10 15.273z" />
                      </svg>
                    ))
                  )}
                </div>
                <span className="ml-2 text-sm text-blue-600">
                  Verified Purchase
                </span>
              </div>
              <p className="text-sm text-gray-500">
                Posted on {review.posted_date}
              </p>
              <h3 className="font-bold text-gray-800 mt-2">{review.summary}</h3>
              <p className="text-gray-600 mt-1">{review.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex-1">
        <div className="flex-1">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Review your experience
          </h2>
          <p className="mb-4 text-gray-600">You're reviewing:</p>
          <p className="font-semibold text-gray-800 mb-4">
            Rehamo Steely PCB Steel frame Wheelchair
          </p>
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Your Rating <span className="text-red-500">*</span>
              </label>
              <div className="flex">
                {[1, 2, 3, 4, 5].map((star) => (
                  <svg
                    key={star}
                    className={`w-8 h-8 cursor-pointer ${
                      star <= (hover || rating)
                        ? "text-yellow-400"
                        : "text-gray-300"
                    }`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    onClick={() => setRating(star)}
                    onMouseEnter={() => setHover(star)}
                    onMouseLeave={() => setHover(rating)}
                  >
                    <path d="M10 15.273l5.29 3.107-1.419-6.053L18 8.574l-6.262-.545L10 2 8.262 8.029 2 8.574l4.309 3.753-1.419 6.053L10 15.273z" />
                  </svg>
                ))}
              </div>
            </div>
            <div>
              <label
                htmlFor="nickname"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Nickname
              </label>
              <input
                type="text"
                id="nickname"
                className="w-full px-3 py-2 border border-gray-300 rounded-md bg-white text-gray-900"
              />
            </div>
            <div>
              <label
                htmlFor="summary"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Summary
              </label>
              <input
                type="text"
                id="summary"
                className="w-full px-3 py-2 border border-gray-300 rounded-md bg-white text-gray-900"
              />
            </div>
            <div>
              <label
                htmlFor="review"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Review
              </label>
              <textarea
                id="review"
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-md bg-white text-gray-900"
              ></textarea>
            </div>
            <button
              type="submit"
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
            >
              Submit Review
            </button>
          </form>
        </div>
      </div>
    </div>
  );

  const tabs: Tab[] = [
    { 
      id: 'details', 
      label: 'Product Details', 
      icon: ListMagnifyingGlass, 
      content: description 
    },
    {
      id: "know_your_size",
      label: "Know Your Size",
      icon: ArrowsOut,
      content: knowYourSize,
    },
    {
      id: "medical_detail",
      label: "Medical Details",
      icon: Stethoscope,
      content: medicalDetail,
    },
    {
      id: "legal_disclaimer",
      label: "Legal Disclaimer",
      icon: ClipboardText,
      content: legalDisclaimer,
    },
    {
      id: "reviews",
      label: "Reviews",
      icon: Star,
      content: renderReviewSection(),
    },
    {
      id: "faqs",
      label: "Faqs",
      icon: SealQuestion,
      content: faqs,
    },
  ];

  

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div className="flex flex-col gap-10 my-2.5 mx-0 md:my-4 lg:my-8">
      {/* Tabs container */}
      <div className="flex flex-row items-center border-b border-gray-200 gap-4 lg:gap-8">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`flex flex-row items-center pb-4 gap-2 cursor-pointer ${
              activeTab === tab.id ? "border-b-4 border-primary" : ""
            }`}
            onClick={() => handleTabClick(tab.id)}
          >
            <img className="w-4 h-4" alt={tab.label} src={tab.icon} />
            <div
              className={`font-medium text-xs md:text-sm lg:text-base ${
                activeTab === tab.id ? "font-semibold" : ""
              }`}
            >
              {tab.label}
            </div>
          </div>
        ))}
      </div>

      {/* Content section */}
      <div className="bg-gray-100 p-8 md:p-10 lg:p-12 rounded-lg flex flex-col gap-12 text-left text-sm md:text-lg lg:text-xl">
        <div className="font-semibold text-lg md:text-xl lg:text-2xl">
          {tabs.find((tab) => tab.id === activeTab)?.label}
        </div>
        <div>
          {activeTab === 'reviews' ? (
            <div>{tabs.find((tab) => tab.id === activeTab)?.content}</div>
          ) : (
            <HtmlInputView content={String(tabs.find((tab) => tab.id === activeTab)?.content ?? '')} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;
