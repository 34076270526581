import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductDescription from "../components/ProductDescription/ProductDescription";
import ProductDetails from "../components/ProductDetails/ProductDetails";
import ProductImages from "../components/ProductImages/ProductImages";
import RelatedProducts from "../components/RelatedProducts/RelatedProducts";
import { useStore } from "../../context/StoreContext";
import { fetchProductDetails } from "../../market/api/MarketApiClient";
import { Product } from "../../market/types/types";

const ProductDetailsPage: React.FC = () => {
  const { urlKey } = useParams<{ urlKey: string }>();
  const { selectedStore, categories } = useStore();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
 //const [cartDetails, setCartDetails] = useState<{ items: Product[] }>({ items: [] });

  const storeCode = selectedStore?.code || "";
  const categoryId = categories.length > 0 ? String(categories[0].id) : "";

  useEffect(() => {
    if (!storeCode || !urlKey) {
      setError("Store code or Product URL Key is missing");
      setLoading(false);
      return;
    }

    const fetchProduct = async () => {
      setLoading(true);
      try {
        const fetchedProduct = await fetchProductDetails(storeCode, urlKey);
        setProduct(fetchedProduct);
        setError(null);
      } catch {
        setError("Error fetching product details");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [storeCode, urlKey]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!product) return <p>Product not found</p>;

  return (
    <div className="relative bg-white w-full text-center text-xs text-[#212529] font-sora flex flex-col items-center justify-start gap-6 sm:gap-8 md:gap-10 lg:gap-12">
      <div className="w-full flex flex-col items-start">
        <div className="w-full flex flex-col md:flex-row items-start justify-between px-4 sm:px-8 md:px-12 lg:px-24 box-border gap-4 sm:gap-6 md:gap-8 lg:gap-10">
          <div className="flex-1 max-w-full md:max-w-[50%]">
            <ProductImages
              product={product}
              storeCode={storeCode}
              categoryId={categoryId}
            />
          </div>
          <div className="flex-1 max-w-full md:max-w-[50%]">
            <ProductDetails product={product} />
          </div>
        </div>
        <div className="w-full mt-6 sm:mt-8 md:mt-10 lg:mt-12 px-4 sm:px-8 md:px-12 lg:px-24 text-base">
          <ProductDescription
            description={product.description}
            knowYourSize={product.know_your_size || ""}
            medicalDetail={product.medical_detail || ""}
            legalDisclaimer={product.legal_disclamer || ""}
            reviewsDetail={product.reviews_detail || []}
            reviewsCount={product.reviews_count || 0}
            faqs={product.faqs || ''}
          />
        </div>

        <div className="w-full mt-6 sm:mt-8 md:mt-10 lg:mt-12 px-4 sm:px-8 md:px-12 lg:px-24 text-2xl">
          <RelatedProducts />
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsPage;
