import { useEffect, useState } from "react";
import { getBuybackItems, getBuybackReviews, updateInventory } from "../api/AdminApi";

interface UpdateInventoryItem {
  id: number;
  productName: string;
  customerName: string;
  customerComments: string;
  csDecision: "PENDING" | "REJECTED" | "ACCEPTED" | "CONDITIONALLY_ACCEPTED";
  inventoryType: "USED_PRODUCT" | "RENTAL_PRODUCT";
  poid: string;
  serialNo: string;
  productSKU: string;
}

export function UpdateInventory() {
  const [data, setData] = useState<UpdateInventoryItem[]>([]);
  const [confirmedItems, setConfirmedItems] = useState<Set<number>>(new Set()); // Track confirmed items

  useEffect(() => {
    const fetchInventoryItems = async () => {
      try {
        const [itemsResponse, reviewsResponse] = await Promise.all([
          getBuybackItems(),
          getBuybackReviews(),
        ]);

        const reviewsMap = new Map(
          reviewsResponse.data.map((review) => [review.item_id, review.cs_review_status])
        );

        const inventoryData: UpdateInventoryItem[] = itemsResponse.data
          .filter((item) => item.payment_status === "PAID" && item.buyback_status === "APPROVED")
          .map((item) => ({
            id: item.id,
            productName: item.product_name,
            customerName: `${item.customer_firstname} ${item.customer_lastname}`,
            customerComments: item.payment_info || "",
            csDecision: reviewsMap.get(item.id) || "PENDING",
            inventoryType: item.inventory_type as "USED_PRODUCT" | "RENTAL_PRODUCT",
            poid: item.po_id,
            serialNo: "",
            productSKU: item.product_sku,
          }));

        setData(inventoryData);
      } catch (error) {
        console.error("Error fetching inventory items:", error);
      }
    };

    fetchInventoryItems();
  }, []);

  const handleSerialNoChange = (index: number, value: string) => {
    setData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, serialNo: value } : item
      )
    );
  };

  const onConfirm = async (id: number, serialNo: string) => {
    try {
      await updateInventory(id, {
        productSerialNumber: serialNo,
        uploadStatus: "UPLOADED",
      });
      setConfirmedItems((prev) => new Set(prev.add(id))); // Mark item as confirmed
      console.log("Update successful");
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Update Inventory
      </h2>
      <table className="w-full">
        <thead>
          <tr className="bg-[#f78a8a] text-white">
            <th className="py-2 px-4 text-left">PRODUCT NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER COMMENTS</th>
            <th className="py-2 px-4 text-left">CS DECISION</th>
            <th className="py-2 px-4 text-left">PRODUCT SKU</th>
            <th className="py-2 px-4 text-left">PO ID</th>
            <th className="py-2 px-4 text-left">SERIAL NO</th>
            <th className="py-2 px-4 text-left">INVENTORY TYPE</th>
            <th className="py-2 px-4 text-left">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id} className="border-b">
              <td className="py-2 px-4">{item.productName}</td>
              <td className="py-2 px-4">{item.customerName}</td>
              <td className="py-2 px-4">{item.customerComments}</td>
              <td className="py-2 px-4">{item.csDecision}</td>
              <td className="py-2 px-4">
                <input
                  type="text"
                  placeholder="Enter SKU"
                  className="w-full p-2 border rounded bg-white"
                  value={item.productSKU}
                  readOnly
                />
              </td>
              <td className="py-2 px-4">{item.poid}</td>
              <td className="py-2 px-4">
                {item.inventoryType === "RENTAL_PRODUCT" ? (
                  <input
                    type="number"
                    value={item.serialNo}
                    onChange={(e) => handleSerialNoChange(index, e.target.value)}
                    className="w-full rounded-md border bg-white border-gray-300 p-1"
                    placeholder="Enter Serial No"
                  />
                ) : (
                  "NA"
                )}
              </td>
              <td className="py-2 px-4">{item.inventoryType}</td>
              <td className="py-2 px-4">
                <button
                  onClick={() => onConfirm(item.id, item.serialNo)}
                  className={`px-4 py-2 rounded-full ${confirmedItems.has(item.id) ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-green-500 text-white"}`}
                  disabled={confirmedItems.has(item.id)} // Disable button after confirmation
                >
                  {confirmedItems.has(item.id) ? "Confirmed" : "Confirm"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
