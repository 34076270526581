import React, { useEffect, useState } from 'react';
import { Product } from '../../types/types';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000';

interface ProductImagesProps {
  product: Product | null;
  storeCode: string;
  categoryId: string;
}

const ProductImages: React.FC<ProductImagesProps> = ({ product, storeCode }) => {
  const [images, setImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);

  const baseUrl = API_BASE_URL + '/market/media/catalog/product';

  useEffect(() => {
    const fetchImages = async () => {
      if (product && product.image_urls) {
        console.log('Product image_urls:', product.image_urls);

        // Create an array of image URLs with base URL appended
        const imageUrls = Object.values(product.image_urls.file)
          .filter(url => url) // Filter out any null or undefined values
          .map(url => baseUrl + "?path=" + url);

        if (imageUrls.length === 0) {
          setImages(['https://via.placeholder.com/537']);
        } else {
          setImages(imageUrls);
        }
      } else {
        setImages(['https://via.placeholder.com/537']);
      }
    };

    fetchImages();
  }, [product, storeCode, baseUrl]);

  const handleImageClick = (index: number) => {
    setSelectedImage(index);
  };

  return (
    <div className="mx-auto flex flex-col items-center justify-start gap-5 sm:gap-6 md:gap-8 lg:gap-10">
      {images.length > 0 ? (
        <>
          <img
            className="image-1-icon-a rounded-[20px] h-[600px] max-w-full object-cover cursor-pointer transition-all border-2 border-transparent"
            alt="Selected Product"
            src={selectedImage !== null ? images[selectedImage] : images[0]}
            onClick={() => handleImageClick(0)}
          />
          <div
            className="image-2-parent-a flex flex-row items-start gap-2 w-full"
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
              scrollbarWidth: 'none', // For Firefox
              WebkitOverflowScrolling: 'touch', // Ensures smooth scrolling on iOS
              // Using type assertion to allow custom properties
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(typeof window !== 'undefined' && window.navigator.userAgent.includes('MSIE') ? { '-ms-overflow-style': 'none' } : {})
            } as React.CSSProperties}
          >
            {images.map((image, index) => (
              <img
                key={index}
                className={`w-[15%] rounded-[10px] object-cover cursor-pointer border-2 ${selectedImage === index ? 'selected border-red-600' : 'border-transparent'}`}
                alt={`Product Thumbnail ${index}`}
                src={image}
                onClick={() => handleImageClick(index)}
                style={{
                  display: 'block',
                  width: '15%', // Maintain consistent width
                }}
              />
            ))}
          </div>
        </>
      ) : (
        <p>No images available</p>
      )}
    </div>
  );
};

export default ProductImages;
