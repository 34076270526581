import React, { createContext, useState, useContext } from 'react';
import { 
  User, 
  StoreView, 
  Category, 
  Product, 
  ProductInCart, 
  FilterOption, 
  CartResponse 
} from '../market/types/types';



interface StoreContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  selectedStore: StoreView | null;
  setSelectedStore: (store: StoreView | null) => void;
  categories: Category[];
  setCategories: (categories: Category[]) => void;
  stores: StoreView[];
  setStores: (stores: StoreView[]) => void;
  selectedProduct: Product | null;
  setSelectedProduct: (product: Product) => void;
  productsInCart: ProductInCart[];
  setProductsInCart: (products: ProductInCart[]) => void;
  selectedCategory: Category | null;
  setSelectedCategory: (category: Category | null) => void;
  filterOptions: FilterOption[] | null;
  setFilterOptions: (filters: FilterOption[] | null) => void;
  products: Product[]; 
  setProducts: (products: Product[]) => void;
  cartDetails: CartResponse | null;
  setCartDetails: (cart: CartResponse | null) => void;
  selectedFilters: { [attribute_code: string]: string[] };
  setSelectedFilters: React.Dispatch<React.SetStateAction<{ [attribute_code: string]: string[] }>>;
  // Pagination
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
  setTotalPages: (pages: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (items: number) => void;
}

// Create the context
const StoreContext = createContext<StoreContextType | undefined>(undefined);

// Create the provider component
export const StoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Define all state variables
  const [user, setUser] = useState<User | null>(null);
  const [selectedStore, setSelectedStore] = useState<StoreView | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [stores, setStores] = useState<StoreView[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productsInCart, setProductsInCart] = useState<ProductInCart[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [filterOptions, setFilterOptions] = useState<FilterOption[] | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [cartDetails, setCartDetails] = useState<CartResponse | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<{ [attribute_code: string]: string[] }>({});
  // Pagination state variables
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  return (
    <StoreContext.Provider value={{
      user, setUser,
      selectedStore, setSelectedStore,
      categories, setCategories,
      stores, setStores,
      selectedProduct, setSelectedProduct,
      productsInCart, setProductsInCart,
      selectedCategory, setSelectedCategory,
      filterOptions, setFilterOptions,
      products, setProducts,
      cartDetails, setCartDetails,
      selectedFilters, setSelectedFilters,
      currentPage, setCurrentPage,
      totalPages, setTotalPages,
      itemsPerPage, setItemsPerPage
    }}>
      {children}
    </StoreContext.Provider>
  );
};

// Custom hook to use the StoreContext
export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};
