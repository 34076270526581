import React, { useState } from 'react';
import FilledStar from '../../assets/sehaa_plp_clp/Star.svg';
import EmptyStar from '../../assets/sehaa_plp_clp/EmptyStar.svg';
import { Product } from '../../types/types';
import placeHolderImage from '../../assets/sehaa_plp_clp/product-placeholder.png';

interface ProductCardProps {
  product: Product;
  onClick: (product: Product) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, onClick }) => {
  const [imageSrc, setImageSrc] = useState(product.media_urls?.thumbnail || placeHolderImage);

  const handleImageError = () => {
    setImageSrc(placeHolderImage);
  };

  return (
    <div
      className="w-full sm:max-w-[240px] shadow-lg rounded-2xl bg-white flex flex-col items-center relative overflow-hidden cursor-pointer h-[400px]"
      onClick={() => onClick(product)}
    >
      <div className="absolute top-0 left-0 bg-primary h-4 sm:h-5 md:h-6 px-1 sm:px-1.5 md:px-2 text-white text-[8px] sm:text-[10px] md:text-xs flex items-center justify-center rounded-tl-xl rounded-br-xl z-40">
        Summer Deal
      </div>
      <div className="sm:p-2 h-[50%] flex items-center justify-center">
        <img                
          alt="Product"
          src={imageSrc}
          onError={handleImageError}
          className="max-h-full max-w-full object-contain"
        />
      </div>
      <div className="w-full flex-grow flex flex-col items-center justify-between p-2 sm:p-4 z-10">
        <div className="w-full flex flex-col items-start gap-1">
          <div className="text-xs sm:text-sm md:text-base font-semibold line-clamp-2">{product.name}</div>
          <div className="w-full flex flex-row items-center gap-1 sm:gap-2 text-[10px] sm:text-xs">
            <div className="flex flex-row items-center gap-0.5">
              {[...Array(5)].map((_, index) => {
                const filledStars = Math.round(product.average_rating / 20);
                return (
                  <img
                    key={index}
                    className="w-2 h-2 sm:w-2.5 sm:h-2.5 md:w-3 md:h-3"
                    alt="Star"
                    src={index < filledStars ? FilledStar : EmptyStar}
                  />
                );
              })}
            </div>
            <div>{product.reviews_count} Reviews</div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-1 sm:gap-2">
          <div className="w-full flex flex-row justify-between text-sm sm:text-base md:text-lg">
            <b className="font-semibold items-end">AED {product.special_price_with_tax ? product.special_price_with_tax : product.price_with_tax}</b>
            {product.special_price_with_tax && (
              <b className="text-[10px] sm:text-xs md:text-sm line-through text-gray-400 items-end">AED {product.price_with_tax}</b>
            )}
          </div>
          <div className="w-full text-[10px] sm:text-xs">
            {product.stock_count > 5 ? (
              <div className="text-green-600">In Stock</div>
            ) : product.stock_count > 0 ? (
              <div className="text-yellow-600">Only {product.stock_count} left in stock</div>
            ) : (
              <div className="text-red-600">Out of stock</div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full h-[12%] items-center justify-center">
        <div className="w-full bg-gray-100 h-[1px] z-20"></div>
        <div className="w-full rounded-lg flex items-center justify-center py-1 sm:py-1.5 md:py-4 text-primary text-center z-30">
            <div className="font-semibold text-[10px] sm:text-xs md:text-sm">Add to cart</div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;