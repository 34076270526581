import React, { useState, useEffect } from 'react';
import ArrowCircleUp from '../../assets/sehaa_plp_clp/ArrowCircleDown.svg';
import { useStore } from '../../../context/StoreContext';
import { fetchFilterOptionsByCategoryId } from '../../api/MarketApiClient';
import { FilterOption, FilterOptionValue } from '../../types/types';

interface SidebarProps {
  updateFilters: (filters: { [attribute_code: string]: string[] }) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ updateFilters }) => {
  const { selectedStore, selectedCategory, selectedFilters, setSelectedFilters } = useStore();
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (selectedStore && selectedCategory) {
      const categoryIdString = selectedCategory.id.toString();
      fetchFilterOptionsByCategoryId(selectedStore.code, categoryIdString)
        .then((filters) => setFilterOptions(filters))
        .catch((error) => console.error('Error fetching filter options:', error));
    }
  }, [selectedCategory, selectedStore]);

  const toggleDropdown = (attribute_code: string) => {
    setIsCategoryOpen((prevState) => ({
      ...prevState,
      [attribute_code]: !prevState[attribute_code],
    }));
  };


  const handleCheckboxChange = (attribute_code: string, value: string) => {
    setSelectedFilters((prevFilters: { [key: string]: string[]; }) => {
      // Ensure proper typing for prevFilters
      const updatedFilters: { [key: string]: string[] } = { ...prevFilters };

      if (!updatedFilters[attribute_code]) {
        updatedFilters[attribute_code] = [];
      }

      const index = updatedFilters[attribute_code].indexOf(value);
      if (index > -1) {
        updatedFilters[attribute_code].splice(index, 1);
      } else {
        updatedFilters[attribute_code].push(value);
      }

      if (updatedFilters[attribute_code].length === 0) {
        delete updatedFilters[attribute_code];
      }

      updateFilters(updatedFilters); // Call parent to update filters
      return updatedFilters; // Return the updated state
    });
  };

  return (
    <div className="w-full md:w-[300px] lg:w-[340px] shadow-[0_0_34px_rgba(0,0,0,0.1)] rounded-[20px] bg-white flex flex-col items-start justify-start">
      <div className="w-full border-b border-gray-200 flex flex-row items-center justify-start p-4 md:p-5 text-[14px]">
        <div className="font-semibold w-full">Shopping Options</div>
      </div>

      {filterOptions.map((filter) => (
        <div key={filter.attribute_code} className="w-full">
          <div
            className={`w-full border-b border-gray-200 flex flex-row items-center justify-between p-4 md:p-5 cursor-pointer ${isCategoryOpen[filter.attribute_code] ? 'active' : ''}`}
            onClick={() => toggleDropdown(filter.attribute_code)}
          >
            <div className="font-bold leading-[150%]">{filter.label}</div>
            <img
              className={`w-[22px] h-[22px] transition-transform duration-200 ${isCategoryOpen[filter.attribute_code] ? 'rotate-180' : ''}`}
              alt="Toggle Dropdown"
              src={ArrowCircleUp}
            />
          </div>

          {isCategoryOpen[filter.attribute_code] && (
            <div className="w-full border-b border-gray-200 flex flex-col items-start justify-start p-4 md:p-5 gap-2">
              {filter.options.map((option: FilterOptionValue) => (
                <label key={option.value} className="flex items-center gap-2 relative cursor-pointer w-full">
                  <input
                    type="checkbox"
                    id={`filter-${filter.attribute_code}-${option.value}`}
                    value={option.value}
                    checked={selectedFilters[filter.attribute_code]?.includes(option.value) || false}
                    onChange={() => handleCheckboxChange(filter.attribute_code, option.value)}
                    className="hidden"
                  />
                  <div
                    className={`w-4 h-4 border-2 ${selectedFilters[filter.attribute_code]?.includes(option.value) ? 'bg-primary border-primary' : 'border-primar'} rounded flex items-center justify-center`}
                  >
                    {selectedFilters[filter.attribute_code]?.includes(option.value) && (
                      <span className="text-white text-[12px] font-bold">✓</span>
                    )}
                  </div>
                  <div className="font-bold truncate" style={{ maxWidth: 'calc(100% - 40px)' }}>
                    {option.label} ({option.count})
                  </div>
                </label>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;