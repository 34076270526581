import React, { useEffect, useCallback, useRef } from "react";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import flags from "../../assets/sehaa_plp_clp/flags.svg";
import WhatsApp from "../../assets/sehaa_plp_clp/WhatsappLogo.svg";
import EnvelopeSimple from "../../assets/sehaa_plp_clp/EnvelopeSimple.svg";
import { StoreView, User } from "../../types/types";
import { useStore } from "../../../context/StoreContext";
import { fetchStores, fetchCategories, fetchToken, fetchCartItems } from "../../api/MarketApiClient"; 

const Header: React.FC = () => {
  const { stores, selectedStore, setUser, setSelectedStore, setCategories, setStores, setCartDetails } = useStore();
  const isInitialized = useRef(false);

  const initializeUser = useCallback(async () => {
    try {
      const session = await fetchAuthSession();
      if (session.userSub) {
        const userAttributes = await fetchUserAttributes();
        console.log(userAttributes);

        if (userAttributes.email) {
          const user: User = {
            id: session.userSub,
            first_name: userAttributes.given_name || "",
            last_name: userAttributes.family_name || "",
            phone: userAttributes.phone_number || "",
            email: userAttributes.email || "",
            verified: false,
          };
          setUser(user);
  
          const token = await fetchToken(user.email);
          console.log('Fetched token:', token);
  
          if (token) {
            const cartDetails = await fetchCartItems(token);
            console.log('Fetched cart details:', cartDetails);
            setCartDetails(cartDetails); 
          }
        } else {
          console.error('Phone number not found in user attributes');
          setUser(null);
        }
      } else {
        console.error('User not found');
        setUser(null);
      }
    } catch (error) {
      console.error("Error initializing user:", error);
      setUser(null);
    }
  }, [setUser, setCartDetails]);

  useEffect(() => {
    const authListener = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          console.log('User has been signed in successfully.');
          initializeUser();
          break;
        case 'signedOut':
          console.log('User has been signed out successfully.');
          setUser(null);
          break;
      }
    });

    if (!isInitialized.current) {
      isInitialized.current = true;
      initializeUser();
      initializeStores();
    }

    return () => {
      authListener();
    };
  }, [initializeUser, setUser]);

  const initializeStores = async () => {
    try {
      const storesData = await fetchStores();
      setStores(storesData);

      const defaultStore =
        storesData.find((store: StoreView) => store.name === "Sehaaonline UAE") || storesData[0];
      setSelectedStore(defaultStore);

      const categories = await fetchCategories(defaultStore.code);
      setCategories(categories.children_data);
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  const handleStoreChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const storeId = Number(event.target.value);
    if (selectedStore?.id !== storeId) {
      const selected = stores.find((store: StoreView) => store.id === storeId) || null;
      setSelectedStore(selected);

      const categories = await fetchCategories(selected?.code || "");
      setCategories(categories.children_data);
    }
  };

  return (
    <div className="w-full flex flex-col items-start justify-start">
      <div className="w-full border border-gray-200 flex flex-col items-start justify-start p-4 md:px-10 lg:px-20">
        <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-0 px-5">
          <div className="flex flex-row items-center justify-start gap-1">
            <img className="w-5 h-5" alt="Flags" src={flags} />
            <select
              value={selectedStore?.id || ""}
              onChange={handleStoreChange}
              className="store-select p-2 border border-gray-300 rounded-md text-xs sm:text-sm md:text-base"
            >
              {stores.map((store: StoreView) => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-1 flex-col sm:flex-row items-start sm:items-center justify-end gap-2 sm:gap-2.5 text-xs sm:text-sm md:text-base">
            <div>9am to 9pm / 7 Days a week</div>
            <div className="hidden sm:block w-1.5 h-1.5 rounded-full bg-gray-300"></div>
            <div className="flex flex-row items-center gap-1">
              <img className="w-4 h-4" alt="WhatsApp" src={WhatsApp} />
              <div className="underline">+971 58 597 9509</div>
            </div>
            <div className="hidden sm:block w-1.5 h-1.5 rounded-full bg-gray-300"></div>
            <div className="flex flex-row items-center gap-1">
              <img className="w-4 h-4" alt="Email" src={EnvelopeSimple} />
              <div className="underline">info@sehaaonline.com</div>
            </div>
            <div className="hidden sm:block w-1.5 h-1.5 rounded-full bg-gray-300"></div>
            <div className="underline">Track Order</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
