import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getBuybackItems,
  updateBuybackItem,
  deleteBuybackItem,
} from "../api/AdminApi";
import { BuybackResponse } from "../types/types";

interface UpcomingBuybackItem {
  id: number;
  productName: string;
  customerFirstname: string;
  customerLastname: string;
  phoneNumber: string;
  emailId: string;
  dateTime: Date;
}

export function UpcomingBuyback() {
  const [data, setData] = useState<UpcomingBuybackItem[]>([]);
  const [upcomingItems, setUpcomingItems] = useState<UpcomingBuybackItem[]>([]);
  const [buybackList, setBuybackList] = useState<UpcomingBuybackItem[]>([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null
  );
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const today = new Date();
  const sevenDaysFromNow = new Date(today);
  sevenDaysFromNow.setDate(today.getDate() + 7);

  // Fetch buyback items when the component mounts
  useEffect(() => {
    const fetchBuybackItems = async () => {
      try {
        const response: BuybackResponse = await getBuybackItems();
        const formattedData = response.data.map((item) => ({
          id: item.id,
          productName: item.product_name,
          customerFirstname: item.customer_firstname,
          customerLastname: item.customer_lastname,
          phoneNumber: item.customer_phone,
          emailId: item.customer_email,
          dateTime: new Date(item.buyback_date),
        }));

        setData(formattedData); // Store the full list for future reference
        filterData(formattedData);
      } catch (error) {
        console.error("Failed to fetch buyback items:", error);
      }
    };

    fetchBuybackItems();
  }, []);

  const filterData = (formattedData: UpcomingBuybackItem[]) => {
    const upcomingItemsFiltered = formattedData
      .filter((item) => item.dateTime >= today && item.dateTime <= sevenDaysFromNow)
      .sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());
  
    const buybackListFiltered = formattedData
      .filter((item) => item.dateTime < today)
      .sort((a, b) => b.dateTime.getTime() - a.dateTime.getTime());
  
    setUpcomingItems(upcomingItemsFiltered);
    setBuybackList(buybackListFiltered);
  };
  

  const handleExtendClick = (item: UpcomingBuybackItem) => {
    setSelectedItemIndex(data.indexOf(item));
    setSelectedDate(item.dateTime);
    setIsPickerOpen(true);
  };

  const handleShareUrl = (item: UpcomingBuybackItem) => {
    const url = `/market/form/${item.id}?productName=${encodeURIComponent(
      item.productName
    )}&purchaseDate=${encodeURIComponent(item.dateTime.toISOString())}`;

    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  // Save the selected date and update the backend
  const handleSaveDateTime = async () => {
    if (selectedItemIndex !== null && selectedDate) {
      const buybackItem = data[selectedItemIndex];
  
      // Get the current time
      const currentTime = new Date();
  
      // Create a new date object that combines the selected date and current time
      const extendedDate = new Date(selectedDate);
      extendedDate.setHours(currentTime.getHours());
      extendedDate.setMinutes(currentTime.getMinutes());
      extendedDate.setSeconds(currentTime.getSeconds());
  
      try {
        const updatedItem = {
          ...buybackItem,
          buybackDate: extendedDate
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
        };
  
        // Update the item in the backend
        await updateBuybackItem(buybackItem.id, updatedItem);
  
        // Update the data state without overwriting the whole list
        setData((prevData) =>
          prevData.map((item, index) =>
            index === selectedItemIndex
              ? { ...item, dateTime: extendedDate }
              : item
          )
        );
  
        // Directly update upcomingItems and buybackList after updating the date
        setUpcomingItems((prevItems) =>
          prevItems.map((item) =>
            item.id === buybackItem.id
              ? { ...item, dateTime: extendedDate }
              : item
          )
        );
  
        setBuybackList((prevItems) =>
          prevItems.map((item) =>
            item.id === buybackItem.id
              ? { ...item, dateTime: extendedDate }
              : item
          )
        );
  
        setIsPickerOpen(false);
        setSelectedItemIndex(null);
      } catch (error) {
        console.error("Failed to update buyback item:", error);
      }
    }
  };
  

  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this buyback item?")) {
      try {
        await deleteBuybackItem(id);
  
        // Remove the item from all relevant lists
        setData((prevData) => prevData.filter((item) => item.id !== id));
        setUpcomingItems((prevItems) => prevItems.filter((item) => item.id !== id));
        setBuybackList((prevItems) => prevItems.filter((item) => item.id !== id));
        
      } catch (error) {
        console.error("Failed to delete buyback item:", error);
      }
    }
  };
  

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Upcoming Buyback
      </h2>
      <table className="w-full">
        <thead>
          <tr className="bg-[#f78a8a] text-white">
            <th className="py-2 px-4 text-left">PRODUCT NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
            <th className="py-2 px-4 text-left">PHONE NUMBER</th>
            <th className="py-2 px-4 text-left">EMAIL ID</th>
            <th className="py-2 px-4 text-left">DATE</th>
            <th className="py-2 px-4 text-left">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {upcomingItems.map((item) => (
            <tr key={item.id} className="border-b">
              <td className="py-2 px-4">{item.productName}</td>
              <td className="py-2 px-4">{`${item.customerFirstname} ${item.customerLastname}`}</td>
              <td className="py-2 px-4">{item.phoneNumber}</td>
              <td className="py-2 px-4">{item.emailId}</td>
              <td className="py-2 px-4">
                {item.dateTime.toLocaleDateString()}{" "}
               
              </td>
              <td className="py-2 px-4">
                <div className="flex gap-2">
                  <button
                    className="rounded-full bg-blue-500 px-4 py-2 text-xs font-semibold text-white transition-all hover:scale-105 hover:shadow-md"
                    onClick={() => handleExtendClick(item)}
                  >
                    EXTEND
                  </button>
                  <button
                    className="rounded-full bg-red-500 px-4 py-2 text-xs font-semibold text-white transition-all hover:scale-105 hover:shadow-md"
                    onClick={() => handleDelete(item.id)}
                  >
                    REMOVE
                  </button>
                  <button
                    className="rounded-full bg-green-500 px-4 py-2 text-xs font-semibold text-white transition-all hover:scale-105 hover:shadow-md"
                    onClick={() => handleShareUrl(item)}
                  >
                    SHARE URL
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Buyback List Section */}
      <h2 className="mt-10 mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Buyback List
      </h2>
      <table className="w-full">
        <thead>
          <tr className="bg-[#f78a8a] text-white">
            <th className="py-2 px-4 text-left">PRODUCT NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
            <th className="py-2 px-4 text-left">PHONE NUMBER</th>
            <th className="py-2 px-4 text-left">EMAIL ID</th>
            <th className="py-2 px-4 text-left">DATE</th>
            <th className="py-2 px-4 text-left">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {buybackList.map((item) => (
            <tr key={item.id} className="border-b">
              <td className="py-2 px-4">{item.productName}</td>
              <td className="py-2 px-4">{`${item.customerFirstname} ${item.customerLastname}`}</td>
              <td className="py-2 px-4">{item.phoneNumber}</td>
              <td className="py-2 px-4">{item.emailId}</td>
              <td className="py-2 px-4">
                {item.dateTime.toLocaleDateString()}{" "}
               
              </td>
              <td className="py-2 px-4">
                <div className="flex gap-2">
                  <button
                    className="rounded-full bg-blue-500 px-4 py-2 text-xs font-semibold text-white transition-all hover:scale-105 hover:shadow-md"
                    onClick={() => handleExtendClick(item)}
                  >
                    EXTEND
                  </button>
                  <button
                    className="rounded-full bg-red-500 px-4 py-2 text-xs font-semibold text-white transition-all hover:scale-105 hover:shadow-md"
                    onClick={() => handleDelete(item.id)}
                  >
                    REMOVE
                  </button>
                  <button
                    className="rounded-full bg-green-500 px-4 py-2 text-xs font-semibold text-white transition-all hover:scale-105 hover:shadow-md"
                    onClick={() => handleShareUrl(item)}
                  >
                    SHARE URL
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Date Picker Modal */}
      {isPickerOpen && selectedDate && (
       <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
       <div className="rounded-lg bg-white p-4 shadow-lg w-64">
         <h3 className="mb-4 text-lg font-semibold">Select Date</h3>
         <DatePicker
           selected={selectedDate}
           onChange={(date) => setSelectedDate(date)}
           dateFormat="dd/MM/yyyy"
           className="mb-4 w-full rounded border border-gray-300 p-2 bg-white text-gray-800"
         />
            <div className="mt-4 flex gap-2">
              <button
                onClick={handleSaveDateTime}
                className="bg-green-500 px-4 py-2 text-white rounded-full"
              >
                Save
              </button>
              <button
                onClick={() => setIsPickerOpen(false)}
                className="bg-gray-500 px-4 py-2 text-white rounded-full"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
