import { useState } from "react";
import { UpcomingBuyback } from "../components/UpcomingBuyback";
import { ReviewBuyback } from "../components/ReviewBuyback";
import { ProductInspection } from "../components/ProductInspection";
import { ProductPayment } from "../components/ProductPayment";
import { UpdateInventory } from "../components/UpdateInventory";
import "react-datepicker/dist/react-datepicker.css";

export default function BuybackManagement() {
  const [activeTab, setActiveTab] = useState("UPCOMING BUYBACK");

  const tabs = [
    "UPCOMING BUYBACK",
    "REVIEW BUYBACK",
    "PRODUCT INSPECTION",
    "PRODUCT PAYMENT",
    "UPDATE INVENTORY",
  ];

  return (
    <div className="">
      <div className="container mx-auto py-10">
        <nav className="tab-container flex justify-center mb-10 bg-white rounded-full p-2 shadow-lg">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab px-6 py-3 rounded-full font-semibold text-base transition-all border-none focus:outline-none outline-none ${
                activeTab === tab
                  ? "bg-[#f78a8a] text-white opacity-100"
                  : "text-black bg-white"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.replace("_", " ")}
            </button>
          ))}
        </nav>

        {activeTab === "UPCOMING BUYBACK" && <UpcomingBuyback />}
        {activeTab === "REVIEW BUYBACK" && <ReviewBuyback />}
        {activeTab === "PRODUCT INSPECTION" && <ProductInspection />}
        {activeTab === "PRODUCT PAYMENT" && <ProductPayment />}
        {activeTab === "UPDATE INVENTORY" && <UpdateInventory />}
      </div>
    </div>
  );
}