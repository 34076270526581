import React, { useState, useEffect } from 'react';
import logoText from '../../assets/sehaa_plp_clp/LogoText.svg';
import logo from '../../assets/sehaa_plp_clp/chrwnx.svg';
import MagnifyingGlass from '../../assets/sehaa_plp_clp/MagnifyingGlass.svg';
import HandHeart from '../../assets/sehaa_plp_clp/HandHeart.svg';
import UserCircle from '../../assets/sehaa_plp_clp/UserCircle.svg';
import ShoppingCart from '../../assets/sehaa_plp_clp/ShoppingCart.svg';
import { useStore } from '../../../context/StoreContext'; // Import the useStore hook
import { fetchCatalogData } from '../../api/MarketApiClient';

interface CatalogCategory {
  catid: string;
  catname: string;
  caturl: string;
}

interface CatalogProduct {
  pid: string;
  pname: string;
  psku: string;
  pdescription: string;
  purl: string;
  price: string;
  image: string;
}

const Navbar: React.FC = () => {
  const { cartDetails } = useStore(); // Access cartDetails from the context
  const cartItemCount = cartDetails?.items?.length || 0;

  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState<CatalogCategory[]>([]);
  const [products, setProducts] = useState<CatalogProduct[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<CatalogCategory[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<CatalogProduct[]>([]);

  useEffect(() => {
    const fetchCatalogSearchSource = async () => {
      try {
        const catalogData = await fetchCatalogData();
        setCategories(catalogData.category);
        setProducts(catalogData.product);
      } catch (error) {
        console.error('Error fetching catalog data:', error);
      }
    };
    fetchCatalogSearchSource();
  }, []);

  // Filter results based on search term
  useEffect(() => {
    if (searchTerm) {
      setFilteredCategories(
        categories.filter((category) =>
          category.catname.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredProducts(
        products.filter((product) =>
          product.pname.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredCategories([]);
      setFilteredProducts([]);
    }
  }, [searchTerm, categories, products]);

  return (
    <div className="w-full bg-[#f05f6c] flex flex-col items-start justify-start p-3 md:p-5 text-left text-sm text-white">
      <div className="w-full flex flex-col md:flex-row items-center justify-start gap-4 md:gap-11 relative">
        {/* Logo */}
        <div className="relative w-[160px] h-[40px] md:w-[212px] md:h-[56.1px] flex-shrink-0 overflow-hidden">
          <img className="absolute top-0 left-0 w-[12.22%] h-full" alt="Logo" src={logo} />
          <img
            className="absolute top-[8.91%] left-[18.59%] w-[81.42%] h-[75.04%]"
            alt="Logo Text"
            src={logoText}
          />
        </div>

        {/* Search Input */}
        <div className="relative w-full h-10 md:h-12">
          <input
            className="w-full h-full rounded-lg bg-white bg-opacity-30 border-none outline-none p-2.5 pr-14 text-xs md:text-base text-gray-800"
            type="text"
            placeholder="What are you looking for?"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <img
            className="absolute right-4 top-1/2 transform -translate-y-1/2 w-5 h-5 pointer-events-none"
            alt="Search"
            src={MagnifyingGlass}
          />
          {/* Dropdown */}
          {(filteredCategories.length > 0 || filteredProducts.length > 0) && (
            <div className="absolute w-full bg-white text-gray-800 mt-1 rounded-md shadow-lg z-10 max-h-64 overflow-y-auto">
              <div className="p-3">
                {/* Category Results */}
                {filteredCategories.length > 0 && (
                  <>
                    <h4 className="font-semibold text-sm mb-2">Category Results</h4>
                    {filteredCategories.map((category) => (
                      <div key={category.catid} className="py-1 px-2 hover:bg-gray-100">
                        <a href={category.caturl} className="text-sm">
                          {category.catname}
                        </a>
                      </div>
                    ))}
                  </>
                )}

                {/* Product Results */}
                {filteredProducts.length > 0 && (
                  <>
                    <h4 className="font-semibold text-sm mt-3 mb-2">Product Results</h4>
                    {filteredProducts.map((product) => (
                      <div key={product.pid} className="flex items-center gap-3 py-1 px-2 hover:bg-gray-100">
                        <img src={product.image} alt={product.pname} className="w-12 h-12 object-cover" />
                        <div>
                          <a href={product.purl} className="text-sm font-medium block">
                            {product.pname}
                          </a>
                          <span className="text-xs text-gray-600">{product.price}</span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Wishlist, Account, Cart */}
        <div className="flex flex-row items-center justify-start gap-2 md:gap-2.5 text-center text-xs">
          <div className="w-[60px] md:w-[81px] flex flex-col items-center justify-center gap-1 md:gap-2">
            <img className="w-5 h-5 md:w-6 md:h-6" alt="Wishlist" src={HandHeart} />
            <div className="leading-none">Wishlist</div>
          </div>
          <div className="w-[60px] md:w-[81px] flex flex-col items-center justify-center gap-1 md:gap-2">
            <img className="w-5 h-5 md:w-6 md:h-6" alt="Account" src={UserCircle} />
            <div className="leading-none">Account</div>
          </div>
          <div className="w-[60px] md:w-[81px] flex flex-col items-center justify-center gap-1 md:gap-2">
            <img className="w-5 h-5 md:w-6 md:h-6" alt="Cart" src={ShoppingCart} />
            <div className="leading-none">
              Cart {cartItemCount > 0 && `(${cartItemCount})`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
