import React, { createContext, useState, useContext } from 'react';
import { BuybackItem, BuybackResponse, BuybackReview, BuybackReviewTable } from '../admin/types/types';

interface BuybackContextType {
  buybackItems: BuybackItem[];
  setBuybackItems: (items: BuybackItem[]) => void;
  buybackReviews: BuybackReview[];
  setBuybackReviews: (reviews: BuybackReview[]) => void;
  buybackReviewTables: BuybackReviewTable[];
  setBuybackReviewTables: (tables: BuybackReviewTable[]) => void;
  buybackResponse: BuybackResponse | null;
  setBuybackResponse: (response: BuybackResponse | null) => void;
}

// Create the context
const BuybackContext = createContext<BuybackContextType | undefined>(undefined);

// Create the provider component
export const BuybackProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [buybackItems, setBuybackItems] = useState<BuybackItem[]>([]);
  const [buybackReviews, setBuybackReviews] = useState<BuybackReview[]>([]);
  const [buybackReviewTables, setBuybackReviewTables] = useState<BuybackReviewTable[]>([]);
  const [buybackResponse, setBuybackResponse] = useState<BuybackResponse | null>(null);

  return (
    <BuybackContext.Provider value={{
      buybackItems, setBuybackItems,
      buybackReviews, setBuybackReviews,
      buybackReviewTables, setBuybackReviewTables,
      buybackResponse, setBuybackResponse,
    }}>
      {children}
    </BuybackContext.Provider>
  );
};

// Custom hook to use the BuybackContext
export const useBuyback = () => {
  const context = useContext(BuybackContext);
  if (context === undefined) {
    throw new Error('useBuyback must be used within a BuybackProvider');
  }
  return context;
};
